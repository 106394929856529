import { QuickStatsColumnModel } from '@handballai/stats-calculation';
import { PlayerScoreStatsDto, PlayerStatsAggregateDto, PlayerStatsDto } from 'src/app/api/hai-api';
import { formatTimeToHoursMinutesAndSeconds, timerFormatHelper } from 'src/app/shared-services/helper/timer-format.helper';
import { DatePipe } from '@angular/common';
import { IndividualLastGameModel, GkLastGameModel, PlayerScoreModel, GameScoreModel } from './dashboard.model';
import { avgPossessionEventsPlayer, possessionEventsPlayer } from './team-quick-stats.helper';


export const transformAvgPlayerQuickStats = (dto: PlayerStatsAggregateDto, isBasicMode = false): QuickStatsColumnModel => {
    if (!dto) return dto as unknown as QuickStatsColumnModel;
    const avgPossession = avgPossessionEventsPlayer(dto);
    const avgPossessionsOffensePositional = avgPossessionEventsPlayer(dto, 'OFFENSE_POSITIONAL');
    const avgPossessionsFastBreak = avgPossessionEventsPlayer(dto, 'FAST_BREAK');
    const avgPossessionsCounterGoals = avgPossessionEventsPlayer(dto, 'COUNTER_GOAL');
    const avgPossessionsEquality = avgPossessionEventsPlayer(dto, 'EQUALITY');
    const avgPossessionsSuperiority = avgPossessionEventsPlayer(dto, 'SUPERIORITY');


    return !dto ? undefined : {
        totalGoals: +dto.avgGoals,
        totalPossessions: isBasicMode ? undefined : +dto.avgPossessions,
        totalPossessionEvents: avgPossession,
        totalFailedShots: (+dto.avgFailedShotsFastBreak)
            + (+dto.avgFailedShotsCounterGoal)
            + (+dto.avgFailedShotsOffensePositional)
            + (+dto.avgPostOut),
        equalityGoals: +dto.avgGoalsEquality,
        equalityPossessions: avgPossessionsEquality,
        superiorityGoals: +dto.avgGoalsSuperiority,
        superiorityPossessions: avgPossessionsSuperiority,
        inferiorityGoals: 0, // not needed
        inferiorityPossessions: 0, // not needed
        totalSaves: 0, // not needed
        counterGoalGoals: +dto.avgGoalsCounterGoals,
        counterGoalPossession: avgPossessionsCounterGoals,
        counterGoalEffectivePossession: avgPossessionsCounterGoals,
        fastBreakGoals: +dto.avgGoalsFastBreak,
        fastBreakPossession: avgPossessionsFastBreak,
        fastBreakEffectivePossession: avgPossessionsFastBreak,
        offensePositionalGoals: +dto.avgGoalsOffensePositional,
        offensePositionalPossession: avgPossessionsOffensePositional,
        offensePositionalEffectivePossession: avgPossessionsOffensePositional,
        totalLostBalls: +dto.avgTotalBalls,
        teamName: '', // not needed
        totalPostOuts: +dto.avgPostOut,
        totalAssist: +dto.avgAssist,
        totalPlayedTime: isBasicMode ? undefined : timerFormatHelper(+dto.avgTime),
    };
};


export const transformTotalPlayerQuickStats = (dto: PlayerStatsAggregateDto, isBasicMode = false): QuickStatsColumnModel => {
    if (!dto) return dto as unknown as QuickStatsColumnModel;
    const totalPossession = possessionEventsPlayer(dto);
    const totalPossessionsOffensePositional = possessionEventsPlayer(dto, 'OFFENSE_POSITIONAL');
    const totalPossessionsFastBreak = possessionEventsPlayer(dto, 'FAST_BREAK');
    const totalPossessionsCounterGoals = possessionEventsPlayer(dto, 'COUNTER_GOAL');
    const totalPossessionsEquality = possessionEventsPlayer(dto, 'EQUALITY');
    const totalPossessionsSuperiority = possessionEventsPlayer(dto, 'SUPERIORITY');

    return !dto ? undefined : {
        totalGoals: +dto.totalGoals,
        totalPossessions: isBasicMode ? undefined : +dto.totalPossessions,
        totalPossessionEvents: totalPossession,
        totalFailedShots: (+dto.totalFailedShotsFastBreak)
            + (+dto.totalFailedShotsCounterGoal)
            + (+dto.totalFailedShotsOffensePositional)
            + (+dto.totalPostOut),
        equalityGoals: +dto.totalGoalsEquality,
        equalityPossessions: totalPossessionsEquality,
        superiorityGoals: +dto.totalGoalsSuperiority,
        superiorityPossessions: totalPossessionsSuperiority,
        inferiorityGoals: 0, // not needed
        inferiorityPossessions: 0, // not needed
        totalSaves: 0, // not needed
        counterGoalGoals: +dto.totalGoalsCounterGoals,
        counterGoalPossession: totalPossessionsCounterGoals,
        counterGoalEffectivePossession: totalPossessionsCounterGoals,
        fastBreakGoals: +dto.totalGoalsFastBreak,
        fastBreakPossession: totalPossessionsFastBreak,
        fastBreakEffectivePossession: totalPossessionsFastBreak,
        offensePositionalGoals: +dto.totalGoalsOffensePositional,
        offensePositionalPossession: totalPossessionsOffensePositional,
        offensePositionalEffectivePossession: totalPossessionsOffensePositional,
        totalLostBalls: +dto.totalLostBalls,
        teamName: '', // not needed
        totalPostOuts: +dto.totalPostOut,
        totalAssist: +dto.totalAssist,
        totalPlayedTime: isBasicMode ? undefined : timerFormatHelper(+dto.totalTime),
    };
};

export const transformPlayerShotStatistic = (dto: PlayerStatsAggregateDto): number[] =>
    !dto ? [0, 0] : [
        +dto.totalGoals,
        (+dto.totalFailedShotsFastBreak)
        + (+dto.totalFailedShotsCounterGoal)
        + (+dto.totalFailedShotsOffensePositional)
        + (+dto.totalPostOut)
    ];

export const transformPlayerGoalByPhaseStats = (dto: PlayerStatsAggregateDto): number[] =>
    !dto ? [0, 0, 0] : [
      +dto.totalGoalsOffensePositional,
      +dto.totalGoalsFastBreak,
      +dto.totalGoalsCounterGoals
    ];

export const transformFieldPlayerLastGamesStats = (
    gameType: string,
    itm: PlayerStatsDto,
    aeId: string|number,
): IndividualLastGameModel => {
    const possessionEvents = itm.goals
        + itm.failedShotsCounterGoal
        + itm.failedShotsFastBreak
        + itm.failedShotsOffensePositional
        + itm.postOut
        + itm.lostBalls
    ;
    const allFailedShots = + itm.failedShotsCounterGoal
        + itm.failedShotsFastBreak
        + itm.failedShotsOffensePositional
        + itm.postOut;
    const shootEff = (itm.goals + allFailedShots) === 0 ? 0 : 100 * (itm.goals / (itm.goals + allFailedShots));

    return gameType === 'COMPLETE_MODE' ? {
        gameId: itm?.gameId,
        gameDate: itm?.gameDate,
        teamsName: itm.gameDesc,
        playedPoses: itm.possessionsPlayed,
        possessionEvents: possessionEvents,
        playerPosition: itm.playerPosition,
        timePlayed: formatTimeToHoursMinutesAndSeconds(itm.timePlayed),
        goals: itm.goals,
        assists: itm.assist,
        failedShots: +itm.failedShotsCounterGoal + itm.failedShotsFastBreak + itm.failedShotsOffensePositional,
        saves: itm.saves,
        postOuts: itm.postOut,
        lostBalls: itm.lostBalls,
        lostBallsPerc: (possessionEvents === 0 ? 0 : (100 * (itm.lostBalls / possessionEvents))).toFixed(1) + ' %',
        eff: (possessionEvents === 0 ? 0 : (100 * (itm.goals / possessionEvents))).toFixed(1) + ' %',
        shootEff: shootEff.toFixed(1) + ' %',
        playerScore: itm.playerScore,
        link: `${location.origin}/account/${aeId}/game/${itm?.gameId}/stats`,
        lostBallsEquality: itm.lostBallsEquality,
        lostBallsSuperiority: itm.lostBallsSuperiority,
        postOutOffensePositional: itm.postOutOffensePositional,
        postOutFastBreak: itm.postOutFastBreak,
        postOutCounterGoal: itm.postOutCounterGoal,
        postOutEquality: itm.postOutEquality,
        postOutSuperiority: itm.postOutSuperiority,
        failedShotsEquality: itm.failedShotsEquality,
        playedTimeOffense: formatTimeToHoursMinutesAndSeconds(+itm.playedTimeOffense),
        playedTimeDefense: formatTimeToHoursMinutesAndSeconds(+itm.playedTimeDefense),
        playedTimeOffenseInferiority: formatTimeToHoursMinutesAndSeconds(+itm.playedTimeOffenseInferiority),
        playedTimeOffenseSuperiority: formatTimeToHoursMinutesAndSeconds(+itm.playedTimeOffenseSuperiority),
        playedTimeOffenseEquality: formatTimeToHoursMinutesAndSeconds(+itm.playedTimeOffenseEquality),
        playedTimeDefenseInferiority: formatTimeToHoursMinutesAndSeconds(+itm.playedTimeDefenseInferiority),
        playedTimeDefenseSuperiority: formatTimeToHoursMinutesAndSeconds(+itm.playedTimeDefenseSuperiority),
        playedTimeDefenseEquality: formatTimeToHoursMinutesAndSeconds(+itm.playedTimeDefenseEquality),
        playerId: itm.playerId,
        playerName: itm.playerName,
        playerBackNumber: itm.playerBackNumber,
        teamId: itm.teamId,
        teamName: itm.teamName,
        lostBallsOffensePositional: itm.lostBallsOffensePositional,
        lostBallsFastBreak: itm.lostBallsFastBreak,
        lostBallsCounterGoal: itm.lostBallsCounterGoal,
        attackFaultProvoke: itm.attackFaultProvoke,
        attackFaultCommit: itm.attackFaultCommit,
        technicalMistakeProvoke: itm.technicalMistakeProvoke,
        technicalMistakeCommit: itm.technicalMistakeCommit,
        lostBallProvoke: itm.lostBallProvoke,
        lostBall: itm.lostBall,
        twoMinCommit: itm.twoMinCommit,
        twoMinProvoke: itm.twoMinProvoke,
        sevenMetersCommit: itm.sevenMetersCommit,
        sevenMetersProvoke: itm.sevenMetersProvoke,
        sevenMSuspCommit: itm.sevenMSuspCommit,
        sevenMSuspProvoke: itm.sevenMSuspProvoke,
        foulCommit: itm.foulCommit,
        foulReceive: itm.foulReceive,
        oneAndOneLost: itm.oneAndOneLost,
        oneAndOneWon: itm.oneAndOneWon,
        block: itm.block,
        shotBlocked: itm.shotBlocked,
        efficiency: itm.efficiency,
        shotEfficiency: itm.shotEfficiency,
        lostBallPerc: itm.lostBallPerc,
        goalsOffensePositional: itm.goalsOffensePositional,
        totalShots: itm.totalShots,
        shots7Meters: itm.shots7Meters,
        gkPostOut: itm.gkPostOut,
        gkSaves7Meters: itm.gkSaves7Meters,
        gkReceivedGoals7Meters: itm.gkReceivedGoals7Meters,
        defensePossOffensePositional: itm.defensePossOffensePositional,
        defensePossFastBreak: itm.defensePossFastBreak,
        defensePossCounterGoal: itm.defensePossCounterGoal,
        defenseReceivedGoalsOffensePositional: itm.defenseReceivedGoalsOffensePositional,
        defenseReceivedGoalsFastBreak: itm.defenseReceivedGoalsFastBreak,
        defenseReceivedGoalsCounterGoal: itm.defenseReceivedGoalsCounterGoal
    } : {
        gameId: itm?.gameId,
        gameDate: itm?.gameDate,
        teamsName: itm.gameDesc,
        playerPosition: itm.playerPosition,
        possessionEvents: possessionEvents,
        goals: itm.goals,
        assists: itm.assist,
        failedShots: itm.failedShotsCounterGoal + itm.failedShotsFastBreak + itm.failedShotsOffensePositional,
        saves: itm.saves,
        postOuts: itm.postOut,
        lostBalls: itm.lostBalls,
        lostBallsPerc: (possessionEvents === 0 ? 0 : (100 * (itm.lostBalls / possessionEvents))).toFixed(1) + ' %',
        eff: (possessionEvents === 0 ? 0 : (100 * (itm.goals / possessionEvents))).toFixed(1) + ' %',
        shootEff: shootEff.toFixed(1) + ' %',
        playerScore: itm.playerScore,
        link: `${location.origin}/account/${aeId}/game/${itm?.gameId}/stats`,
        lostBallsEquality: itm.lostBallsEquality,
        lostBallsSuperiority: itm.lostBallsSuperiority,
        postOutOffensePositional: itm.postOutOffensePositional,
        postOutFastBreak: itm.postOutFastBreak,
        postOutCounterGoal: itm.postOutCounterGoal,
        postOutEquality: itm.postOutEquality,
        postOutSuperiority: itm.postOutSuperiority,
        failedShotsEquality: itm.failedShotsEquality,
        playedTimeOffense: formatTimeToHoursMinutesAndSeconds(+itm.playedTimeOffense),
        playedTimeDefense: formatTimeToHoursMinutesAndSeconds(+itm.playedTimeDefense),
        playedTimeOffenseInferiority: formatTimeToHoursMinutesAndSeconds(+itm.playedTimeOffenseInferiority),
        playedTimeOffenseSuperiority: formatTimeToHoursMinutesAndSeconds(+itm.playedTimeOffenseSuperiority),
        playedTimeOffenseEquality: formatTimeToHoursMinutesAndSeconds(+itm.playedTimeOffenseEquality),
        playedTimeDefenseInferiority: formatTimeToHoursMinutesAndSeconds(+itm.playedTimeDefenseInferiority),
        playedTimeDefenseSuperiority: formatTimeToHoursMinutesAndSeconds(+itm.playedTimeDefenseSuperiority),
        playedTimeDefenseEquality: formatTimeToHoursMinutesAndSeconds(+itm.playedTimeDefenseEquality),
        playerId: itm.playerId,
        playerName: itm.playerName,
        playerBackNumber: itm.playerBackNumber,
        teamId: itm.teamId,
        teamName: itm.teamName,
        lostBallsOffensePositional: itm.lostBallsOffensePositional,
        lostBallsFastBreak: itm.lostBallsFastBreak,
        lostBallsCounterGoal: itm.lostBallsCounterGoal,
        attackFaultProvoke: itm.attackFaultProvoke,
        attackFaultCommit: itm.attackFaultCommit,
        technicalMistakeProvoke: itm.technicalMistakeProvoke,
        technicalMistakeCommit: itm.technicalMistakeCommit,
        lostBallProvoke: itm.lostBallProvoke,
        lostBall: itm.lostBall,
        twoMinCommit: itm.twoMinCommit,
        twoMinProvoke: itm.twoMinProvoke,
        sevenMetersCommit: itm.sevenMetersCommit,
        sevenMetersProvoke: itm.sevenMetersProvoke,
        sevenMSuspCommit: itm.sevenMSuspCommit,
        sevenMSuspProvoke: itm.sevenMSuspProvoke,
        foulCommit: itm.foulCommit,
        foulReceive: itm.foulReceive,
        oneAndOneLost: itm.oneAndOneLost,
        oneAndOneWon: itm.oneAndOneWon,
        block: itm.block,
        shotBlocked: itm.shotBlocked,
        efficiency: itm.efficiency,
        shotEfficiency: itm.shotEfficiency,
        lostBallPerc: itm.lostBallPerc,
        goalsOffensePositional: itm.goalsOffensePositional,
        totalShots: itm.totalShots,
        shots7Meters: itm.shots7Meters,
        gkPostOut: itm.gkPostOut,
        gkSaves7Meters: itm.gkSaves7Meters,
        gkReceivedGoals7Meters: itm.gkReceivedGoals7Meters,
        defensePossOffensePositional: itm.defensePossOffensePositional,
        defensePossFastBreak: itm.defensePossFastBreak,
        defensePossCounterGoal: itm.defensePossCounterGoal,
        defenseReceivedGoalsOffensePositional: itm.defenseReceivedGoalsOffensePositional,
        defenseReceivedGoalsFastBreak: itm.defenseReceivedGoalsFastBreak,
        defenseReceivedGoalsCounterGoal: itm.defenseReceivedGoalsCounterGoal
    };
};

export const transformGkSavesTotalStatistic = (dto: PlayerStatsAggregateDto): number[] =>
    !dto ? [0, 0] : [
        +dto.totalSaves,
        +dto.totalReceivedGoals
    ];

export const transformGkSavesAvgStatistics = (dto: PlayerStatsAggregateDto): number[] =>
    !dto ? [0, 0] : [
        (+dto.totalSaves) + (+dto.totalGkPostOut),
        +dto.totalReceivedGoals
    ];

export const transformGkLastGamesStats = (
    gameType: string,
    itm: PlayerStatsDto,
    aeId: string|number,
): GkLastGameModel => {
    // Already prepared to count goalkeeper goals as well
    const possessionEvents =
        itm.goals
        + itm.failedShotsCounterGoal
        + itm.failedShotsFastBreak
        + itm.failedShotsOffensePositional
        + itm.postOut
        + itm.lostBalls
    ;
    return gameType === 'COMPLETE_MODE' ? {
        gameDate: itm?.gameDate,
        teamsName: itm.gameDesc,
        playedPoses: itm.possessionsPlayed,
        possessionEvents: possessionEvents,
        playerPosition: itm.playerPosition,
        timePlayed: formatTimeToHoursMinutesAndSeconds(itm.timePlayed),
        goals: itm.goals,
        goalsReceived: itm.receivedGoals,
        saves: itm.saves,
        lostBalls: itm.lostBalls,
        saveEff: (itm.saves + itm.receivedGoals === 0 ? 0 : 100 * (itm.saves / (itm.saves + itm.receivedGoals))).toFixed(1) + ' %',
        playerScore: itm.playerScore,
        link: `${location.origin}/account/${aeId}/game/${itm?.gameId}/stats`,
    } : {
        gameDate: itm?.gameDate,
        teamsName: itm.gameDesc,
        possessionEvents: possessionEvents,
        playerPosition: itm.playerPosition,
        goals: itm.goals,
        goalsReceived: itm.receivedGoals,
        saves: itm.saves,
        lostBalls: itm.lostBalls,
        saveEff: (itm.saves + itm.receivedGoals === 0 ? 0 : 100 * (itm.saves / (itm.saves + itm.receivedGoals))).toFixed(1) + ' %',
        playerScore: itm.playerScore,
        link: `${location.origin}/account/${aeId}/game/${itm?.gameId}/stats`,
    };

};

export const transformPlayerStatsToScore = (
    teamName: string,
    playerStatsAggregatedDto: PlayerStatsAggregateDto[]
): PlayerScoreModel[] =>
    playerStatsAggregatedDto
        .map(psa => ({
            player: psa.playerName,
            team: teamName,
            games: +psa.totalGamesPlayed,
            playerScore: (+psa.totalPlayerScore).toFixed(1),
            score5: (+psa.totalScore5).toFixed(1),
            scorePos: (+psa.totalScorePos).toFixed(1),
            scoreNeg: (+psa.totalScoreNeg).toFixed(1),
            scoreAttack: (+psa.totalScoreAttack).toFixed(1),
            scoreDef: (+psa.totalScoreDef).toFixed(1),
            playerScoreAsNumber: +psa.totalPlayerScore,
            playerScoreGame: +psa.totalGamesPlayed > 0 ? ((+psa.totalPlayerScore) / (+psa.totalGamesPlayed)).toFixed(1) : 0,
            position: psa.playerPosition,
            minutesTotal: (+psa.totalTime / 60).toFixed(1),
            playerScoreMin: +psa.totalTime > 0 ? ((+psa.totalPlayerScore) / (+psa.totalTime / 60)).toFixed(1) : 0
        }) as PlayerScoreModel);

export const transformGameStatsToScore = (
    playerStatsDto: PlayerScoreStatsDto[], datePipe: DatePipe
): GameScoreModel[] =>
    playerStatsDto.map(psd => ({
        gameDesc: `${datePipe.transform(psd.gameDate, 'mediumDate')} - ${psd.gameDesc}`,
        playerName: psd.playerName,
        teamName: psd.teamName,
        gameId: psd.gameId,
        playerPosition: psd.playerPosition,
        timePlayed: (+psd.timePlayed / 60).toFixed(1),
        playerScore: psd.playerScore ? (+psd.playerScore).toFixed(1) : 0,
        score5: psd.score5 ? (+psd.score5).toFixed(1) : 0,
        scorePos: psd.scorePos ? (+psd.scorePos).toFixed(1) : 0,
        scoreNeg: psd.scoreNeg ? (+psd.scoreNeg).toFixed(1) : 0,
        scoreAttack: psd.scoreAttack ? (+psd.scoreAttack).toFixed(1) : 0,
        scoreDef: psd.scoreDef ? (+psd.scoreDef).toFixed(1) : 0,
        playerScoreTimePlayed: psd.playerScore && psd.timePlayed > 0 ? (100 * (psd.playerScore / psd.timePlayed)).toFixed(1) : 0
    } as GameScoreModel));
