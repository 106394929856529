<div class="confirm-modal-wrapper my-8">
  <div class="py-4 w-full">
    <div style="width: 100%" class="display-flex-space-between">
      <span></span>
      <span class="span-with-add-btn">{{ 'Select a Playlist' | translate }}</span>
      <ion-button style="margin-right: 30px" [tooltip]="'Add new Playlist' | translate" (click)="createPlaylist()" color="secondary">
        <ion-icon style="padding: 0 4px;" name="add-outline"></ion-icon>
      </ion-button>
    </div>
    <hr class="mx-auto my-2" />
    <span class="explanation-text">{{ 'Select the playlist where you want to add the videos' | translate }}</span>
  </div>
  <div class="input-group text-black mt-2">
    <div class="select-group mb-2">
      <ion-item *ngFor="let playlist of scoutingService.playListFolders$ | async" (click)="onPlaylistChange(playlist)"
                lines="full" class="item-default clickAllowedSource">
          <!--<div style="display: flex; align-items: center">-->
            <!--<ion-icon *ngIf="folder.children?.length"
                      class="chevron" (click)="onExpandClick(folder, $event)"
                      [name]="folder.expanded ? 'chevron-down-outline' : 'chevron-forward-outline'"></ion-icon>-->
            <!--<ion-avatar class="clickAllowedSource">
              <img class="clickAllowedSource" alt="folder" src="/assets/icons/playlist-icon.png" />
            </ion-avatar>-->

          <!--</div>-->
          <div class="folder-item" style="display: flex; width: 100%; cursor: pointer">
            <ion-label class="clickAllowedSource"> {{ playlist.name }} </ion-label>
          </div>
          <!--<ion-icon *ngIf="!folderToBeMovedId && !selectedFolderId" style="margin-left: auto; padding-top: 13px" class="chevron"
                    [tooltip]="'Add Subfolder'" (click)="onAddSubFolderClick(folder, $event)"
                    name="add-outline"></ion-icon>-->
      </ion-item>
      <!--<div class="mb-2 select-and-btn">
        <p></p>
        <ion-label class="select-label">{{ 'Select a Playlist' | translate }}</ion-label>

        <ion-button [tooltip]="'Add new Playlist' | translate" (click)="createPlaylist()" color="secondary">
          <ion-icon style="padding: 0 4px;" name="add-outline"></ion-icon>
        </ion-button>
      </div>
      <ng-select
        [placeholder]="'Select a Playlist' | translate"
        maxSelectedItems="1"
        [searchable]="true"
        [clearable]="false"
        [(ngModel)]="selectedPlayList"
        (change)="onPlaylistChange($event)"
      >
        <ng-option *ngFor="let playList of scoutingService.playListFolders$ | async" [value]="playList">
          {{ playList.name }}
        </ng-option>
      </ng-select>-->
    </div>
  </div>
  <div class="button-wrapper mt-6">
    <span (click)="onCancel()" class="close-btn">{{ 'Close' | translate }}</span>
    <!--<ion-button color="primary" (click)="onConfirmName()">{{ 'Confirm' | translate }}</ion-button>-->
  </div>
</div>
