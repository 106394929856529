import { Component, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import {
  Connection,
  CounterModel,
  Goals,
  QuickStatsColumnModel,
} from "@handballai/stats-calculation";
import { LoadingController, ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { ChartOptions, ChartType } from "chart.js";
import { Color, Label } from "ng2-charts";
import { NGXLogger } from "ngx-logger";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { tap, map, filter, concatMap } from "rxjs/operators";
import {
  GameListFilterDto,
  PaceAndPhaseDto,
  PlayerStatsAggregateDto, TeamsStatsDto,
  TeamStatsAggregateDto,
} from 'src/app/api/hai-api';
import { CoreService } from "src/app/shared-services/core.service";
import { GameSystemStatsViewModel } from "src/app/shared-services/statistics/playbyplay/consumer/game-system/model/game-system-view.model";
import { createTeamPdf } from "../../pages/aehandler-module/pages/dashboard-module/components/team-analysis/team-pdf.helper";
import {
  shotsMap,
  eventTableLeftMap,
  eventTableRightMap,
} from '../../pages/aehandler-module/pages/dashboard-module/dashboard-handler';
import { PlayersTotalGameModel } from "../../pages/aehandler-module/pages/dashboard-module/dashboard.model";
import {
  TotalGoalConnectionsProps,
  transformGoalConnectionCounter,
} from '../../pages/aehandler-module/pages/dashboard-module/goal-connection-helper';
import {
  transformTotalTeamQuickStats,
  transformAvgTeamQuickStats,
  playersTableMap,
  transformPlayByPlayToViewModel,
  gameSystemColumns,
  getPlayerColumns, getPlayerColumnsForTeams,
} from '../../pages/aehandler-module/pages/dashboard-module/team-quick-stats.helper';
export interface DashTeamConf {
  mode: "COMPLETE_MODE" | "LITE_MODE";
  aeOverride?: number;
}
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { toUpperCase } from "../../pages/aehandler-module/pages/game-module/components/pdf-report/pdf-report.component";
import { IonicSelectableComponent } from 'ionic-selectable';
import { CsvDownloadService } from '../../../shared-services/download/csv-download.service';
import { formatTimeToHoursMinutesAndSeconds } from 'src/app/shared-services/helper/timer-format.helper';

@Component({
  selector: "app-dash-team-stats",
  templateUrl: "./dash-team-stats.component.html",
  styleUrls: ["./dash-team-stats.component.scss"],
})
export class DashTeamStatsComponent implements OnInit, OnDestroy {
  confData: DashTeamConf;
  @Input() set conf(value: DashTeamConf) {
    if (!value) return;
    this.confData = value;
    // TODO: Maybe we should move here service method calls to get new subject on every conf change
  }
  @ViewChild('selectableColumns', { static: false }) selectableColumns: IonicSelectableComponent;
  public goalConnectionOffenseVisible = true;
  @Input() printData: any;
  @Input() set printConf(value: any) {
    if (!value) return;
    this.printPdf(value);
  }

  public printPdf(value) {
    if (
        this.teamAggregatedTbl3$Last == undefined ||
        this.teamAggregatedTbl4$Last == undefined ||
        this.last6GamesColumns$Last == undefined ||
        this.last6GamesRows$Last == undefined ||
        this.teamPlayers$Last == undefined ||
        this.totalQuickStats$Last == undefined ||
        this.totalQuickStats$Last == undefined ||
        this.total7MetersGoals$Last == undefined ||
        this.total7MetersFailedShoots$Last == undefined ||
        this.avgQuickStats$Last == undefined ||
        this.avg7MetersGoals$Last == undefined ||
        this.avg7MetersFailedShoots$Last == undefined
    )
      return;

    this.overviewAccordionOpen = true;

    const printOptions = {
      teamEventsTbl3: this.teamAggregatedTbl3$Last,
      teamEventsTbl4: this.teamAggregatedTbl4$Last,
      paceAndPhaseColumns: this.last6GamesColumns$Last,
      paceAndPhase: this.last6GamesRows$Last,
      playersTbl: this.teamPlayers$Last,
      totalQuickStats: this.totalQuickStats$Last,
      teams: value.teams,
      quickStats: {
        totalQuickStats: this.totalQuickStats$Last || null,
        total7MetersGoals: this.total7MetersGoals$Last || 0,
        total7MetersFailedShoots: this.total7MetersFailedShoots$Last || 0,
        avgQuickStats: this.avgQuickStats$Last || null,
        avg7MetersGoals: this.avg7MetersGoals$Last || 0,
        avg7MetersFailedShoots: this.avg7MetersFailedShoots$Last || 0,
      },
      gameType: this.selectedType,
      gameSystemTbl: this.gameSystemStatsViewLast,
      selectedGames: value.selectedGames ? value.selectedGames : [],
      goalkeeperReport: value.goalkeeperReport
    };

    createTeamPdf(
        printOptions,
        this.loadingCtrl,
        this.translateService,
        this.modalCtrl,
        value.selectedTeam,
        value.selectedTeamData,
        this.core,
    );
  }

  public overviewAccordionOpen = true;
  public offDefAccordionOpen = false;
  public teamEventsAccordionOpen = false;
  public paceAndPhasesAccordionOpen = false;
  public playersAccordionOpen = false;
  public gameSystemAccordionOpen = false;

  public leftWingCheckbox = false;
  public rightWingCheckbox = false;
  public leftBackCheckbox = false;
  public rightBackCheckbox = false;
  public centerBackCheckbox = false;
  public linePlayerCheckbox = false;
  public goalkeeperCheckbox = false;

  public availablePositions = [
    'CONNECT_G_LEFT_WING:CONNECT_S_LEFT_WING',
    'CONNECT_G_RIGHT_WING:CONNECT_S_RIGHT_WING',
    'CONNECT_G_CENTER_6M:CONNECT_S_CENTER_6M',
    'CONNECT_G_LEFT_6M:CONNECT_S_LEFT_6M',
    'CONNECT_G_RIGHT_6M:CONNECT_S_RIGHT_6M',
    'CONNECT_G_CENTER_9M:CONNECT_S_CENTER_9M',
    'CONNECT_G_LEFT_9M:CONNECT_S_LEFT_9M',
    'CONNECT_G_RIGHT_9M:CONNECT_S_RIGHT_9M',
    'CONNECT_G_7_METERS:CONNECT_S_7_METERS',
    'CONNECT_G_OPPOSITE_FIELD:CONNECT_S_OPPOSITE_FIELD'
  ];

  public superiorityEqualityTableColsShown = false;
  public offenseDefenseTableColsShown = false;
  public pacePhasesTableColsShown = false;
  public playersTableColsShown = false;
  public gameSystemTableColsShown = false;

  @HostListener('window:resize', ['$event'])
  async onResize() {
    setTimeout(() => {
      this.setTableColumns();
      if (this.core.isMobileDevice && this.last6GamesColumns.length) {
        this.last6GamesColumns$Last = this.last6GamesColumns.slice(0, 3);
      } else if (this.last6GamesColumns.length) {
        this.last6GamesColumns$Last = [...this.last6GamesColumns];
      }
      this.pacePhasesTableColsShown = false;
    }, 100);
  }

  @Input() set season(season: GameListFilterDto[]) {
    if (!season) return;
    this.last6GamesColumns$ = this.core.dashboardsService.teamLast6$.pipe(
      map((columns) => {
        const filteredColumns =
          season.length === 0
            ? columns
            : columns.filter((column) =>
                season.find((izm) => izm.gameId === column.gameId)
              );
        return this.transformPaceAndPhaseColumns(filteredColumns);
      })
    );
    this.last6GamesRows$ = this.core.dashboardsService.teamLast6$.pipe(
      concatMap((rows) => {
        const filteredRows =
        season.length === 0
          ? rows
          : rows.filter((column) =>
              season.find((row) => row.gameId === column.gameId)
            );
        return this.transformPaceAndPhaseRows(filteredRows);
      }),
    );

    this.subscriptions.push(
      this.last6GamesColumns$.subscribe(
        (d) => {
          if (this.core.isMobileDevice) {
            this.last6GamesColumns$Last = d.slice(0, 3);
          } else {
            this.last6GamesColumns$Last = d;
          }
          this.last6GamesColumns = d;
        }
      ),
      this.last6GamesRows$.subscribe((d) => (this.last6GamesRows$Last = d)),
    );
  }

  public translationsInited$ = new BehaviorSubject<boolean>(false);

  subscriptions: Subscription[] = [];
  constructor(
    private readonly logger: NGXLogger,
    public readonly core: CoreService,
    private readonly sanitizer: DomSanitizer,
    private readonly loadingCtrl: LoadingController,
    private readonly modalCtrl: ModalController,
    private readonly translateService: TranslateService,
    private csvDownloadService: CsvDownloadService,
  ) {
    core.dashboardsService.resetAll();
    this.teamAggregated$ = core.dashboardsService.teamAggregated$;
    this.teamOpponentAggregated$ = core.dashboardsService.teamOpponentAggregated$;
    const teamAggregatedSub = this.teamAggregated$.subscribe((res: any) => {
      if (res) {
        const offenseEventRowCounterGoal = {
          phase: 'COUNTER GOAL',
          goals: res.totalGoalsCounterGoals,
          failedShots: res.totalFailedShotsCounterGoal,
          postOuts: res.totalPostOutCounterGoal,
          saves: res.totalSavesCounterGoals,
          lostBalls: res.totalLostBallsCounterGoals,
          possessions: res.totalPossCounterGoal,
          eff: res.totalGoalsCounterGoals !== '0' && res.totalPossCounterGoal !== '0' ?
              ((parseInt(res.totalGoalsCounterGoals) / parseInt(res.totalPossCounterGoal)) * 100).toFixed(0) : '0',
          shootingEff: res.totalGoalsCounterGoals !== '0' && res.totalFailedShotsCounterGoal !== '0' ?
              ((parseInt(res.totalGoalsCounterGoals) / (parseInt(res.totalGoalsCounterGoals) + parseInt(res.totalFailedShotsCounterGoal))) * 100).toFixed(0) : '0',
          lostBallsEff: res.totalLostBallsCounterGoals !== '0' && res.totalPossCounterGoal !== '0' ?
              ((parseInt(res.totalLostBallsCounterGoals) / parseInt(res.totalPossCounterGoal)) * 100).toFixed(0) : '0',
        };
        const offenseEventRowFastBreak = {
          phase: 'FAST BREAK',
          goals: res.totalGoalsFastBreak,
          failedShotsFastBreak: res.totalFailedShotsFastBreak,
          postOuts: res.totalPostOutFastBreak,
          saves: res.totalSavesFastBreak,
          lostBalls: res.totalLostBallsFastBreak,
          possessions: res.totalPossFastBreak,
          eff: res.totalGoalsFastBreak !== '0' && res.totalPossFastBreak !== '0' ?
              ((parseInt(res.totalGoalsFastBreak) / parseInt(res.totalPossFastBreak)) * 100).toFixed(0) : '0',
          shootingEff: res.totalGoalsFastBreak !== '0' && res.totalGoalsFastBreak !== '0' ?
              ((parseInt(res.totalGoalsFastBreak) / (parseInt(res.totalGoalsFastBreak) + parseInt(res.totalFailedShotsFastBreak))) * 100).toFixed(0) : '0',
          lostBallsEff: res.totalLostBallsFastBreak !== '0' && res.totalPossFastBreak !== '0' ?
              ((parseInt(res.totalLostBallsFastBreak) / parseInt(res.totalPossFastBreak)) * 100).toFixed(0) : '0',
        };
        const offenseEventRowOffensePositional = {
          phase: 'OFFENSE POSITIONAL',
          goals: res.totalGoalsOffensePositional,
          failedShootsOffensePositional: res.totalFailedShotsOffensePositional,
          postOuts: res.totalPostOutOffensePositional,
          saves: res.totalSavesOffensePositional,
          lostBalls: res.totalLostBallsOffensePositional,
          possessions: res.totalPossOffensePositional,
          eff: res.totalGoalsOffensePositional !== '0' && res.totalPossOffensePositional !== '0' ?
              ((parseInt(res.totalGoalsOffensePositional) / parseInt(res.totalPossOffensePositional)) * 100).toFixed(0) : '0',
          shootingEff: res.totalGoalsOffensePositional !== '0' && res.totalFailedShotsOffensePositional !== '0' ?
              ((parseInt(res.totalGoalsOffensePositional) / (parseInt(res.totalGoalsOffensePositional) + parseInt(res.totalFailedShotsOffensePositional))) * 100).toFixed(0) : '0',
          lostBallsEff: res.totalLostBallsOffensePositional !== '0' && res.totalPossOffensePositional !== '0' ?
              ((parseInt(res.totalLostBallsOffensePositional) / parseInt(res.totalPossOffensePositional)) * 100).toFixed(0) : '0',
        };

        this.offenseEventsValRows = [offenseEventRowOffensePositional, offenseEventRowCounterGoal, offenseEventRowFastBreak];


        const equality6vs6OffenseEventRow = {
          phase: 'Equality 6vs6',
          numOfPossessions: res.totalPossessionsEquality6vs6,
          numOfGoals: res.totalGoalsEquality6vs6,
          numOfShots: res.totalShotsEquality6vs6,
          numOfLostBalls: res.totalLostBallsEquality6vs6,
          eff: res.totalGoalsEquality6vs6 !== '0' && res.totalPossessionsEquality6vs6 !== '0' ?
              ((parseInt(res.totalGoalsEquality6vs6) / parseInt(res.totalPossessionsEquality6vs6)) * 100).toFixed(0) : '0',
          shooting: res.totalGoalsEquality6vs6 !== '0' && res.totalShotsEquality6vs6 !== '0' ?
              ((parseInt(res.totalGoalsEquality6vs6) / parseInt(res.totalShotsEquality6vs6)) * 100).toFixed(0) : '0',
          lostBallEff: res.totalLostBallsEquality6vs6 !== '0' && res.totalPossessionsEquality6vs6 !== '0' ?
              ((parseInt(res.totalLostBallsEquality6vs6) / parseInt(res.totalPossessionsEquality6vs6)) * 100).toFixed(0) : '0',
        };

        const equality5p1OffenseEventRow = {
          phase: 'Equality 5+1 vs 6',
          numOfPossessions: res.totalPossessionsEquality5p1vs6,
          numOfGoals: res.totalGoalsEquality5p1vs6,
          numOfShots: res.totalShotsEquality5p1vs6,
          numOfLostBalls: res.totalLostBallsEquality5p1vs6,
          eff: res.totalGoalsEquality5p1vs6 !== '0' && res.totalPossessionsEquality5p1vs6 !== '0' ?
              ((parseInt(res.totalGoalsEquality5p1vs6) / parseInt(res.totalPossessionsEquality5p1vs6)) * 100).toFixed(0) : '0',
          shooting: res.totalGoalsEquality5p1vs6 !== '0' && res.totalShotsEquality5p1vs6 !== '0' ?
              ((parseInt(res.totalGoalsEquality5p1vs6) / parseInt(res.totalShotsEquality5p1vs6)) * 100).toFixed(0) : '0',
          lostBallEff: res.totalLostBallsEquality5p1vs6 !== '0' && res.totalPossessionsEquality5p1vs6 !== '0' ?
              ((parseInt(res.totalLostBallsEquality5p1vs6) / parseInt(res.totalPossessionsEquality5p1vs6)) * 100).toFixed(0) : '0',
        };

        const superiority6vs5OffenseEventRow = {
          phase: 'Superiority 6vs5',
          numOfPossessions: res.totalPossessionsSuperiority6vs5,
          numOfGoals: res.totalGoalsSuperiority6vs5,
          numOfShots: res.totalShotsSuperiority6vs5,
          numOfLostBalls: res.totalLostBallsSuperiority6vs5,
          eff: res.totalGoalsSuperiority6vs5 !== '0' && res.totalPossessionsSuperiority6vs5 !== '0' ?
              ((parseInt(res.totalGoalsSuperiority6vs5) / parseInt(res.totalPossessionsSuperiority6vs5)) * 100).toFixed(0) : '0',
          shooting: res.totalGoalsSuperiority6vs5 !== '0' && res.totalShotsSuperiority6vs5 !== '0' ?
              ((parseInt(res.totalGoalsSuperiority6vs5) / parseInt(res.totalShotsSuperiority6vs5)) * 100).toFixed(0) : '0',
          lostBallEff: res.totalLostBallsSuperiority6vs5 !== '0' && res.totalPossessionsSuperiority6vs5 !== '0' ?
              ((parseInt(res.totalLostBallsSuperiority6vs5) / parseInt(res.totalPossessionsSuperiority6vs5)) * 100).toFixed(0) : '0',
        };

        const superiority7vs6OffenseEventRow = {
          phase: 'Superiority 7vs6',
          numOfPossessions: res.totalPossessionsSuperiority7vs6,
          numOfGoals: res.totalGoalsSuperiority7vs6,
          numOfShots: res.totalShotsSuperiority7vs6,
          numOfLostBalls: res.totalLostBallsSuperiority7vs6,
          eff: res.totalGoalsSuperiority7vs6 !== '0' && res.totalPossessionsSuperiority7vs6 !== '0' ?
              ((parseInt(res.totalGoalsSuperiority7vs6) / parseInt(res.totalPossessionsSuperiority7vs6)) * 100).toFixed(0) : '0',
          shooting: res.totalGoalsSuperiority7vs6 !== '0' && res.totalShotsSuperiority7vs6 !== '0' ?
              ((parseInt(res.totalGoalsSuperiority7vs6) / parseInt(res.totalShotsSuperiority7vs6)) * 100).toFixed(0) : '0',
          lostBallEff: res.totalLostBallsSuperiority7vs6 !== '0' && res.totalPossessionsSuperiority7vs6 !== '0' ?
              ((parseInt(res.totalLostBallsSuperiority7vs6) / parseInt(res.totalPossessionsSuperiority7vs6)) * 100).toFixed(0) : '0',
        };
        this.offenseEventsRows = [equality6vs6OffenseEventRow, equality5p1OffenseEventRow, superiority6vs5OffenseEventRow, superiority7vs6OffenseEventRow];
      }
    });
    const teamOpponentAggregateSub = this.teamOpponentAggregated$.subscribe((res: any) => {
      if (res) {
        const defenseEventRowCounterGoal = {
          phase: 'COUNTER GOAL',
          goals: res.totalGoalsCounterGoals,
          failedShots: res.totalFailedShotsCounterGoal,
          postOuts: res.totalPostOutCounterGoal,
          saves: res.totalSavesCounterGoals,
          lostBalls: res.totalLostBallsCounterGoals,
          possessions: res.totalPossCounterGoal,
          eff: res.totalGoalsCounterGoals !== '0' && res.totalPossCounterGoal !== '0' ?
              ((parseInt(res.totalGoalsCounterGoals) / parseInt(res.totalPossCounterGoal)) * 100).toFixed(0) : '0',
          shootingEff: res.totalGoalsCounterGoals !== '0' && res.totalFailedShotsCounterGoal !== '0' ?
              ((parseInt(res.totalGoalsCounterGoals) / (parseInt(res.totalGoalsCounterGoals) + parseInt(res.totalFailedShotsCounterGoal))) * 100).toFixed(0) : '0',
          lostBallsEff: res.totalLostBallsCounterGoals !== '0' && res.totalPossCounterGoal !== '0' ?
              ((parseInt(res.totalLostBallsCounterGoals) / parseInt(res.totalPossCounterGoal)) * 100).toFixed(0) : '0',
        };
        const defenseEventRowFastBreak = {
          phase: 'FAST BREAK',
          goals: res.totalGoalsFastBreak,
          failedShotsFastBreak: res.totalFailedShotsFastBreak,
          postOuts: res.totalPostOutFastBreak,
          saves: res.totalSavesFastBreak,
          lostBalls: res.totalLostBallsFastBreak,
          possessions: res.totalPossFastBreak,
          eff: res.totalGoalsFastBreak !== '0' && res.totalPossFastBreak !== '0' ?
              ((parseInt(res.totalGoalsFastBreak) / parseInt(res.totalPossFastBreak)) * 100).toFixed(0) : '0',
          shootingEff: res.totalGoalsFastBreak !== '0' && res.totalGoalsFastBreak !== '0' ?
              ((parseInt(res.totalGoalsFastBreak) / (parseInt(res.totalGoalsFastBreak) + parseInt(res.totalFailedShotsFastBreak))) * 100).toFixed(0) : '0',
          lostBallsEff: res.totalLostBallsFastBreak !== '0' && res.totalPossFastBreak !== '0' ?
              ((parseInt(res.totalLostBallsFastBreak) / parseInt(res.totalPossFastBreak)) * 100).toFixed(0) : '0',
        };
        const defenseEventRowOffensePositional = {
          phase: 'OFFENSE POSITIONAL',
          goals: res.totalGoalsOffensePositional,
          failedShootsOffensePositional: res.totalFailedShotsOffensePositional,
          postOuts: res.totalPostOutOffensePositional,
          saves: res.totalSavesOffensePositional,
          lostBalls: res.totalLostBallsOffensePositional,
          possessions: res.totalPossOffensePositional,
          eff: res.totalGoalsOffensePositional !== '0' && res.totalPossOffensePositional !== '0' ?
              ((parseInt(res.totalGoalsOffensePositional) / parseInt(res.totalPossOffensePositional)) * 100).toFixed(0) : '0',
          shootingEff: res.totalGoalsOffensePositional !== '0' && res.totalFailedShotsOffensePositional !== '0' ?
              ((parseInt(res.totalGoalsOffensePositional) / (parseInt(res.totalGoalsOffensePositional) + parseInt(res.totalFailedShotsOffensePositional))) * 100).toFixed(0) : '0',
          lostBallsEff: res.totalLostBallsOffensePositional !== '0' && res.totalPossOffensePositional !== '0' ?
              ((parseInt(res.totalLostBallsOffensePositional) / parseInt(res.totalPossOffensePositional)) * 100).toFixed(0) : '0',
        };

        this.defenseEventsValRows = [defenseEventRowOffensePositional, defenseEventRowCounterGoal, defenseEventRowFastBreak];
        // -----------------------

        const equality6vs6DefenseEventRow = {
          phase: 'Equality 6vs6',
          numOfPossessions: res.totalPossessionsEquality6vs6,
          numOfGoals: res.totalGoalsEquality6vs6,
          numOfShots: res.totalShotsEquality6vs6,
          numOfLostBalls: res.totalLostBallsEquality6vs6,
          eff: res.totalGoalsEquality6vs6 !== '0' && res.totalPossessionsEquality6vs6 !== '0' ?
              ((parseInt(res.totalGoalsEquality6vs6) / parseInt(res.totalPossessionsEquality6vs6)) * 100).toFixed(0) : '0',
          shooting: res.totalGoalsEquality6vs6 !== '0' && res.totalShotsEquality6vs6 !== '0' ?
              ((parseInt(res.totalGoalsEquality6vs6) / parseInt(res.totalShotsEquality6vs6)) * 100).toFixed(0) : '0',
          lostBallEff: res.totalLostBallsEquality6vs6 !== '0' && res.totalPossessionsEquality6vs6 !== '0' ?
              ((parseInt(res.totalLostBallsEquality6vs6) / parseInt(res.totalPossessionsEquality6vs6)) * 100).toFixed(0) : '0',
        };

        const equality5p1DefenseEventRow = {
          phase: 'Equality 5+1 vs 6',
          numOfPossessions: res.totalPossessionsEquality5p1vs6,
          numOfGoals: res.totalGoalsEquality5p1vs6,
          numOfShots: res.totalShotsEquality5p1vs6,
          numOfLostBalls: res.totalLostBallsEquality5p1vs6,
          eff: res.totalGoalsEquality5p1vs6 !== '0' && res.totalPossessionsEquality5p1vs6 !== '0' ?
              ((parseInt(res.totalGoalsEquality5p1vs6) / parseInt(res.totalPossessionsEquality5p1vs6)) * 100).toFixed(0) : '0',
          shooting: res.totalGoalsEquality5p1vs6 !== '0' && res.totalShotsEquality5p1vs6 !== '0' ?
              ((parseInt(res.totalGoalsEquality5p1vs6) / parseInt(res.totalShotsEquality5p1vs6)) * 100).toFixed(0) : '0',
          lostBallEff: res.totalLostBallsEquality5p1vs6 !== '0' && res.totalPossessionsEquality5p1vs6 !== '0' ?
              ((parseInt(res.totalLostBallsEquality5p1vs6) / parseInt(res.totalPossessionsEquality5p1vs6)) * 100).toFixed(0) : '0',
        };

        const superiority6vs5DefenseEventRow = {
          phase: 'Superiority 6vs5',
          numOfPossessions: res.totalPossessionsSuperiority6vs5,
          numOfGoals: res.totalGoalsSuperiority6vs5,
          numOfShots: res.totalShotsSuperiority6vs5,
          numOfLostBalls: res.totalLostBallsSuperiority6vs5,
          eff: res.totalGoalsSuperiority6vs5 !== '0' && res.totalPossessionsSuperiority6vs5 !== '0' ?
              ((parseInt(res.totalGoalsSuperiority6vs5) / parseInt(res.totalPossessionsSuperiority6vs5)) * 100).toFixed(0) : '0',
          shooting: res.totalGoalsSuperiority6vs5 !== '0' && res.totalShotsSuperiority6vs5 !== '0' ?
              ((parseInt(res.totalGoalsSuperiority6vs5) / parseInt(res.totalShotsSuperiority6vs5)) * 100).toFixed(0) : '0',
          lostBallEff: res.totalLostBallsSuperiority6vs5 !== '0' && res.totalPossessionsSuperiority6vs5 !== '0' ?
              ((parseInt(res.totalLostBallsSuperiority6vs5) / parseInt(res.totalPossessionsSuperiority6vs5)) * 100).toFixed(0) : '0',
        };

        const superiority7vs6DefenseEventRow = {
          phase: 'Superiority 7vs6',
          numOfPossessions: res.totalPossessionsSuperiority7vs6,
          numOfGoals: res.totalGoalsSuperiority7vs6,
          numOfShots: res.totalShotsSuperiority7vs6,
          numOfLostBalls: res.totalLostBallsSuperiority7vs6,
          eff: res.totalGoalsSuperiority7vs6 !== '0' && res.totalPossessionsSuperiority7vs6 !== '0' ?
              ((parseInt(res.totalGoalsSuperiority7vs6) / parseInt(res.totalPossessionsSuperiority7vs6)) * 100).toFixed(0) : '0',
          shooting: res.totalGoalsSuperiority7vs6 !== '0' && res.totalShotsSuperiority7vs6 !== '0' ?
              ((parseInt(res.totalGoalsSuperiority7vs6) / parseInt(res.totalShotsSuperiority7vs6)) * 100).toFixed(0) : '0',
          lostBallEff: res.totalLostBallsSuperiority7vs6 !== '0' && res.totalPossessionsSuperiority7vs6 !== '0' ?
              ((parseInt(res.totalLostBallsSuperiority7vs6) / parseInt(res.totalPossessionsSuperiority7vs6)) * 100).toFixed(0) : '0',
        };
        this.defenseEventsRows = [equality6vs6DefenseEventRow, equality5p1DefenseEventRow, superiority6vs5DefenseEventRow, superiority7vs6DefenseEventRow];
      }
    });
    this.shots$ = core.dashboardsService.teamAggregated$.pipe(
      tap((ind) => this.generateSvgConnGoals(ind)),
      map((ind) => shotsMap(ind))
    );
    this.goalConnection$ = core.dashboardsService.teamAggregated$.pipe(
      map((dto) => transformGoalConnectionCounter(dto))
    );
    this.goalConnectionDef$ = core.dashboardsService.teamOpponentAggregated$.pipe(
        map((dto) => transformGoalConnectionCounter(dto))
    );
    this.totalQuickStats$ = core.dashboardsService.teamAggregated$.pipe(
      map((dto) => transformTotalTeamQuickStats(dto))
    );
    this.avgQuickStats$ = core.dashboardsService.teamAggregated$.pipe(
      map((dto) => transformAvgTeamQuickStats(dto))
    );
    this.total7MetersGoals$ = core.dashboardsService.teamAggregated$.pipe(
      map((dto) => +dto?.totalGoals7Meters)
    );
    this.total7MetersFailedShoots$ =
      core.dashboardsService.teamAggregated$.pipe(
        map((dto) => +dto?.totalShots7Meters - +dto?.totalGoals7Meters)
      );
    this.avg7MetersGoals$ = core.dashboardsService.teamAggregated$.pipe(
      map((dto) => +dto?.avgGoals7Meters)
    );
    this.avg7MetersFailedShoots$ = core.dashboardsService.teamAggregated$.pipe(
      map((dto) => +dto?.avgShots7Meters - +dto?.avgGoals7Meters)
    );
    this.totalGoalsFailedShots$ = core.dashboardsService.teamAggregated$.pipe(
      map((dto) =>
        !dto
          ? [1, 1]
          : [
              +dto.totalGkReceivedOffensePositional +
                +dto.totalGkReceivedCounterGoal +
                +dto.totalGkReceivedFastBreak,
              +dto.totalOpponentFailedShots,
            ]
      )
    );

    this.totalGoalsSaves$ = core.dashboardsService.teamAggregated$.pipe(
      map((dto) =>
        !dto
          ? [1, 1]
          : [
              +dto.totalGkReceivedOffensePositional +
                +dto.totalGkReceivedCounterGoal +
                +dto.totalGkReceivedFastBreak,
              +dto.totalGkSaveOffensePositional +
                +dto.totalGkSaveFastBreak +
                +dto.totalGkSaveCounterGoal,
            ]
      )
    );
    this.teamAggregatedTbl1$ = core.dashboardsService.teamAggregated$.pipe(
      map(async (ind) => {
        if (!ind) {
          return [];
        }
        return [
          // {
          //   template: "Time Played",
          //   total: timerFormatHelper(+ind.totalTime),
          //   average: timerFormatHelper(+ind.avgTime),
          // },
          {
            template: await this.tg(_("Possesions")),
            // template: await this.tg(_("Possesions")),
            total: ind.totalPossessions,
            average: ind.avgPossessions,
          },
          {
            template: await this.tg(_("Goals")),
            total: ind.totalGoals,
            average: ind.avgGoals,
          },
          {
            template: await this.tg(_("Saves")),
            total: ind.totalSaves,
            average: ind.avgSaves,
          },
          {
            template: await this.tg(_("Fail Shots")),
            total:
              +ind.totalFailedShotsCounterGoal +
              +ind.totalFailedShotsFastBreak +
              +ind.totalFailedShotsOffensePositional,
            average:
              +ind.avgFailedShotsCounterGoal +
              +ind.avgFailedShotsFastBreak +
              +ind.avgFailedShotsOffensePositional,
          },
          {
            template: await this.tg(_("Post Outs")),
            total:
              +ind.totalPostOutCounterGoal +
              +ind.totalPostOutFastBreak +
              +ind.totalPostOutOffensePositional,
            average:
              +ind.avgPostOutCounterGoal +
              +ind.avgPostOutFastBreak +
              +ind.avgPostOutOffensePositional,
          },
        ];
      })
    );
    this.teamAggregatedTbl2$ = core.dashboardsService.teamAggregated$.pipe(
      map(async (ind) => {
        if (!ind) {
          return [];
        }
        return [
          {
            playerInfo: await this.tg(_("Total Goals")),
            offensePositional: ind.totalGoalsOffensePositional,
            fastBreak: ind.totalGoalsFastBreak,
            counterGoal: ind.totalGoalsCounterGoals,
          },
          {
            playerInfo: await this.tg(_("Total Saves")),
            offensePositional: ind.totalSavesOffensePositional,
            fastBreak: ind.totalSavesFastBreak,
            counterGoal: ind.totalSavesCounterGoals,
          },
          {
            playerInfo: await this.tg(_("Total Lost Balls")),
            offensePositional: ind.totalLostBallsOffensePositional,
            fastBreak: ind.totalLostBallsFastBreak,
            counterGoal: ind.totalLostBallsCounterGoals,
          },
        ];
      })
    );

    this.teamAggregatedTbl3$ = core.dashboardsService.teamAggregated$.pipe(
        concatMap((ind) => eventTableLeftMap(ind, this.tg))
    );
    this.teamAggregatedTbl4$ = core.dashboardsService.teamAggregated$.pipe(
        concatMap((ind) => eventTableRightMap(ind, this.tg))
    );
    this.teamPlayers$ = core.dashboardsService.teamPlayers$.pipe(
      tap((ind) => this.generateSvgConnGoals(null, ind)),
      map((team) => playersTableMap(team, this.selectedType === "LITE_MODE"))
    );

    this.teamPlayers$.subscribe(teamPlayers => {
      this.teamPlayers = teamPlayers;
      this.teamPlayersCopy = [...teamPlayers];
    });
    this.team$ = core.dashboardsService.team$;
    this.subscriptions.push(
        this.teamAggregated$.subscribe((team) => {
          if (team) {
            const playedTimeRow = {
              phase: 'Played Time',
              defense: formatTimeToHoursMinutesAndSeconds(+team.totalPlayedTimeDefense),
              offense: formatTimeToHoursMinutesAndSeconds(+team.totalPlayedTimeOffense)
            };

            const playedTimeInferiorityRow = {
              phase: 'Inferiority',
              defense: formatTimeToHoursMinutesAndSeconds(+team.totalPlayedTimeDefenseInferiority),
              offense: formatTimeToHoursMinutesAndSeconds(+team.totalPlayedTimeOffenseInferiority)
            };

            const playedTimeSuperiorityRow = {
              phase: 'Superiority',
              defense: formatTimeToHoursMinutesAndSeconds(+team.totalPlayedTimeDefenseSuperiority),
              offense: formatTimeToHoursMinutesAndSeconds(+team.totalPlayedTimeOffenseSuperiority)
            };

            const playedTimeEqualityRow = {
              phase: 'Equality',
              defense: formatTimeToHoursMinutesAndSeconds(+team.totalPlayedTimeDefenseEquality),
              offense: formatTimeToHoursMinutesAndSeconds(+team.totalPlayedTimeOffenseEquality)
            };

            this.playedTimeRows = [playedTimeRow, playedTimeInferiorityRow, playedTimeSuperiorityRow, playedTimeEqualityRow];
          }
        })
    );
    this.gameSystemStatsView = core.dashboardsService.filteredPlayByPlay$.pipe(
        map((itm) => transformPlayByPlayToViewModel(itm))
    );

    this.subscriptions = [
      this.teamAggregatedTbl3$.subscribe(
        (d) => (this.teamAggregatedTbl3$Last = d)
      ),
      this.teamAggregatedTbl4$.subscribe(
        (d) => (this.teamAggregatedTbl4$Last = d)
      ),
      this.teamPlayers$.subscribe((d) => (this.teamPlayers$Last = d)),
      this.totalQuickStats$.subscribe((d) => (this.totalQuickStats$Last = d)),
      this.total7MetersGoals$.subscribe(
        (d) => (this.total7MetersGoals$Last = d)
      ),
      this.total7MetersFailedShoots$.subscribe(
        (d) => (this.total7MetersFailedShoots$Last = d)
      ),
      this.avgQuickStats$.pipe(filter((d)=>!!d)).subscribe((d) => ( this.avgQuickStats$Last = Object.keys(d).reduce((memo, key)=> {
          memo[key] = isNaN(d[key]) ? d[key] : d[key] % 1 === 0 ? d[key] : parseFloat(d[key].toFixed(1))
          return memo;
        },{})
      )),
      this.avg7MetersGoals$.subscribe((d) => (this.avg7MetersGoals$Last = d)),
      this.avg7MetersFailedShoots$.subscribe(
        (d) => (this.avg7MetersFailedShoots$Last = d)
      ),
      this.gameSystemStatsView.subscribe(
        (d) => {
          this.gameSystemStatsViewLast = d;
        }
      ),
      teamAggregatedSub,
      teamOpponentAggregateSub
    ];
  }

  public async setTableColumns() {
    this.superiorityEqualityTableColsShown = false;
    this.offenseDefenseTableColsShown = false;
    this.pacePhasesTableColsShown = false;
    this.playersTableColsShown = false;
    this.gameSystemTableColsShown = false;
    const gameSystemCols = await gameSystemColumns(this.tg);
    const playerColumns = await getPlayerColumnsForTeams(this.selectedType === 'LITE_MODE', this.tg);
    const playerColumnsWithoutSorting = await getPlayerColumnsForTeams(this.selectedType === 'LITE_MODE', this.tg);
    this.playerColumns = playerColumns.sort((a, b) => a.prop.localeCompare(b.prop));
    this.playedTimeCols = [
      {prop: "phase", name: await this.tg(_("Phase")), flexGrow: 1, frozenLeft: true, width: 130},
      {prop: "defense", name: await this.tg(_("Defense")), flexGrow: 1, width: 80},
      {prop: "offense", name: await this.tg(_("Offense")), flexGrow: 1, width: 80},
    ];
    if (!this.core.isMobileDevice) {
      this.gameSystemColumns = gameSystemCols;
      this.selectedPlayersColumns = playerColumnsWithoutSorting.slice(0, 35);
      this.offenseEventsValCols = [
        {prop: "phase", name: await this.tg(_("Phase")), flexGrow: 1, frozenLeft: true, width: 130},
        {prop: "goals", name: await this.tg(_("Goals")), flexGrow: 1, width: 80},
        {prop: "postOuts", name: await this.tg(_("Post Outs")), flexGrow: 1, width: 80},
        {prop: "saves", name: await this.tg(_("Saves")), flexGrow: 1, width: 80},
        {prop: "lostBalls", name: await this.tg(_("Lost Balls")), flexGrow: 1, width: 80},
        {prop: "possessions", name: await this.tg(_("Possessions")), flexGrow: 1, width: 80},
        {prop: "eff", name: await this.tg(_("Efficiency %")), percentage: true, flexGrow: 1, width: 80},
        {prop: "shootingEff", name: await this.tg(_("Shooting Efficiency %")), percentage: true, flexGrow: 1, width: 80},
        {prop: "lostBallsEff", name: await this.tg(_("Lost Balls %")), percentage: true, flexGrow: 1, width: 80},
      ];
      this.offenseEventsCols = [
        {prop: "phase", name: await this.tg(_("Equality / Superiority")), frozenLeft: true, flexGrow: 1, width: 130},
        {prop: "numOfPossessions", name: await this.tg(_("Nº Posessions")), flexGrow: 1, width: 80},
        {prop: "numOfGoals", name: await this.tg(_("Nº Goals")), flexGrow: 1, width: 80},
        {prop: "numOfShots", name: await this.tg(_("Nº Shots")), flexGrow: 1, width: 80},
        {prop: "numOfLostBalls", name: await this.tg(_("Nº Lost Balls")), flexGrow: 1, width: 80},
        {prop: "eff", name: await this.tg(_("EFF %")), percentage: true, flexGrow: 1, width: 80},
        {prop: "shooting", name: await this.tg(_("Shooting %")), percentage: true, flexGrow: 1, width: 80},
        {prop: "lostBallEff", name: await this.tg(_("Lost Ball Eff %")), percentage: true, flexGrow: 1, width: 80},
      ];
    } else {
      this.gameSystemColumns = gameSystemCols.slice(0, 4);
      this.selectedPlayersColumns = playerColumnsWithoutSorting.slice(0, 3);
      this.offenseEventsCols = [
        {prop: "phase", name: await this.tg(_("Equality / Superiority")), frozenLeft: true, flexGrow: 1, width: 130},
        {prop: "numOfPossessions", name: await this.tg(_("Nº Posessions")), flexGrow: 1, width: 75},
        {prop: "numOfGoals", name: await this.tg(_("Nº Goals")), flexGrow: 1, width: 75},
        {prop: "numOfShots", name: await this.tg(_("Nº Shots")), flexGrow: 1, width: 75}
      ];

      this.offenseEventsValCols = [
        {prop: "phase", name: await this.tg(_("Phase")), flexGrow: 1, frozenLeft: true, width: 130},
        {prop: "goals", name: await this.tg(_("Goals")), flexGrow: 1, width: 75},
        {prop: "postOuts", name: await this.tg(_("Post Outs")), flexGrow: 1, width: 75},
        {prop: "saves", name: await this.tg(_("Saves")), flexGrow: 1, width: 75},
      ];
    }

  }

  async ngOnInit(): Promise<void> {
    const playerColumns = await getPlayerColumnsForTeams(this.selectedType === 'LITE_MODE', this.tg);
    this.playerColumns = playerColumns.sort((a, b) => a.prop.localeCompare(b.prop));
    await this.setTableColumns();

    this.cOptions.title.text = await this.tg(_("SAVES / GOALS %"));
    this.c2Options.title.text = await this.tg(_("GOALS / NO GOALS %"));
    this.pieChartOptions.title.text = await this.tg(_("Goals Phase"));
    this.pieChartOptions.plugins.subtitle.text = await this.tg(_("In which Phase they score the most?"));

    this.cOptions_PDF.title.text = toUpperCase(await this.tg(_("SAVES / GOALS %")));
    this.c2Options_PDF.title.text = toUpperCase(await this.tg(_("GOALS / NO GOALS %")));

    this.legend = [
      { key: "eff", desc: await this.tg(_("% Efficiency")), value: "00.0%" },
      { key: "shot", desc: await this.tg(_("% Shot Efficiency")), value: "00.0%" },
      { key: "losts", desc: await this.tg(_("% Losts Balls")), value: "00.0%" },
    ];
    this.totalAvg = [
      { prop: "template", name: await this.tg(_("Events")), resizable: false, sortable: true, frozenLeft: true, flexGrow: 2 },
      { prop: "total", name: await this.tg(_("Total")), flexGrow: 1 },
      { prop: "average", name: await this.tg(_("Average")), flexGrow: 1 },
    ];

    this.columnsT1 = [
      { prop: "template", name: "", resizable: false, sortable: false, frozenLeft: true, flexGrow: 1 },
      { prop: "total", name: await this.tg(_("Total")), flexGrow: 1 },
      { prop: "average", name: await this.tg(_("Average")), flexGrow: 1 },
    ];

    this.rowsT1 = [
      { template: await this.tg(_("Time Played")), total: "00", average: "00:00" },
      { template: await this.tg(_("Possesions")), total: "00", average: "00:00" },
      { template: await this.tg(_("Goals")), total: "00", average: "00:00" },
      { template: await this.tg(_("Saves")), total: "00", average: "00:00" },
      { template: await this.tg(_("Lost Balls")), total: "00", average: "00:00" },
      { template: await this.tg(_("Steal Balls")), total: "00", average: "00:00" },
      { template: await this.tg(_("Shot Balls")), total: "00", average: "00:00" },
    ];

    this.counters = [
      { prop: "playerInfo", name: "", resizable: false, sortable: false, frozenLeft: true, flexGrow: 1 },
      { prop: "offensePositional", name: await this.tg(_("Offense Positional")), flexGrow: 1 },
      { prop: "counterGoal", name: await this.tg(_("Counter Goal")), flexGrow: 1 },
      { prop: "fastBreak", name: await this.tg(_("Fastbreak")), flexGrow: 1 },
    ];
    this.phasesRows = [
      { template: await this.tg(_("Pace")) },
      { template: await this.tg(_("Total Goals")) },
      { template: await this.tg(_("Total Saves")) },
      { template: await this.tg(_("Total Lost Balls")) },
      { template: await this.tg(_("% Eff IN OFFENSE POSITIONAL")) },
      { template: await this.tg(_("% EFF IN FASTBREAK")) },
      { template: await this.tg(_("% EFF IN COUNTERGOAL")) },
      { template: await this.tg(_("% EFFICIENCY")) },
      { template: await this.tg(_("% SHOOTING EFFICIENCY")) },
      { template: await this.tg(_("% LOST BALLS EFFICIENCY")) },
      { template: await this.tg(_("LINK TO THE GAME")) },
    ];
    this.translationsInited$.next(true);
  }

  tg = (def: string, skipLangs: string[] = []) => {
    return this.core.i18nService.tg(def, skipLangs);
  };

  public personalizeColumnsClick() {
    this.selectableColumns?.open();
  }

  public onPositionCheckboxClick() {
    const selectedPositions = [];
    if (this.leftWingCheckbox) selectedPositions.push('lw');
    if (this.rightWingCheckbox) selectedPositions.push('rw');
    if (this.leftBackCheckbox) selectedPositions.push('lb');
    if (this.rightBackCheckbox) selectedPositions.push('rb');
    if (this.centerBackCheckbox) selectedPositions.push('cb');
    if (this.linePlayerCheckbox) selectedPositions.push('lp');
    if (this.goalkeeperCheckbox) selectedPositions.push('gk');

    if (selectedPositions.length === 0) {
      this.teamPlayers = [...this.teamPlayersCopy];
    } else {
      this.teamPlayers = this.teamPlayersCopy.filter(player => selectedPositions.includes(player.position));
    }
  }

  public onSelectColumns() {
    if (this.core.isMobileDevice && !this.playersTableColsShown && this.selectedPlayersColumns.length > 4) {
      this.playersTableColsShown = true;
    }
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  // teams$: Observable<EntityFilterDto[]>;
  // seasonOptions$: Observable<GameListFilter[]>;
  teamAggregated$: Observable<TeamStatsAggregateDto>;
  teamOpponentAggregated$: Observable<TeamStatsAggregateDto>;
  teamAggregatedTbl1$;
  teamAggregatedTbl2$;
  shots$: Observable<CounterModel[]>;
  goalConnection$: Observable<CounterModel[]>;
  goalConnectionDef$: Observable<CounterModel[]>;
  totalGoalsFailedShots$: Observable<number[]>;
  totalGoalsSaves$: Observable<number[]>;
  gameSystemStatsView: Observable<GameSystemStatsViewModel[]>;
  offenseEventsRows = [];
  defenseEventsRows = [];
  offenseEventsValRows = [];
  defenseEventsValRows = [];

  teamAggregatedTbl3$: Observable<{
    average?: any;
    template: any;
    total: string | number;
  }[]>;
  teamAggregatedTbl3$Last;
  teamAggregatedTbl4$;
  teamAggregatedTbl4$Last;
  last6GamesColumns$;
  last6GamesColumns$Last;
  last6GamesColumns;
  last6GamesRows$;
  last6GamesRows$Last;
  teamPlayers$: Observable<PlayersTotalGameModel[]>;
  team$: Observable<TeamsStatsDto[]>;
  teamPlayers$Last;
  totalQuickStats$: Observable<QuickStatsColumnModel>;
  totalQuickStats$Last;
  total7MetersGoals$: Observable<number>;
  total7MetersGoals$Last;
  total7MetersFailedShoots$: Observable<number>;
  total7MetersFailedShoots$Last;
  avgQuickStats$: Observable<QuickStatsColumnModel>;
  avgQuickStats$Last;
  avg7MetersGoals$: Observable<number>;
  avg7MetersGoals$Last;
  avg7MetersFailedShoots$: Observable<number>;
  avg7MetersFailedShoots$Last;
  gameSystemStatsViewLast;

  public teamPlayers: PlayersTotalGameModel[] = [];
  public teamPlayersCopy: PlayersTotalGameModel[] = [];

  svgConnGoals$: BehaviorSubject<SafeHtml> = new BehaviorSubject<SafeHtml>(
    null
  );
  connections: Connection[] = [];
  goals: Goals[] = [];
  async generateSvgConnGoals(
    teamAggregated: TeamStatsAggregateDto = null,
    playerStats: PlayerStatsAggregateDto[] = null
  ) {
    // if (!teamAggregated) { return; }
    // const subject = (isSecond) ? this.svgConnGoals2$ : this.svgConnGoals1$;

    if (teamAggregated) {
      const possitions = ["Gk", "Lw", "Rw", "Lb", "Rb", "Cb", "Lp"];
      const connections: Connection[] = [];
      for (let pos_a of possitions)
        for (let pos_b of possitions)
          if (pos_a != pos_b) {
            const value = +(await teamAggregated)[
              "totalAssist" + pos_a + pos_b
            ];
            if (value)
              connections.push({
                startPos: pos_a.toLowerCase(),
                endPos: pos_b.toLowerCase(),
                weight: value,
              });
          }
      this.connections = connections;
    }

    // TODO: Fetch from server or agregate from players
    if (playerStats) {
      const goals: Goals[] = [];
      const gObj = (await playerStats).reduce(
        (base, p) => {
          base[p.playerPosition] += +p.totalGoals;
          return base;
        },
        {
          gk: 0,
          lw: 0,
          rw: 0,
          lb: 0,
          rb: 0,
          cb: 0,
          lp: 0,
        }
      );
      for (const pos in gObj)
        goals.push({ label: "" + gObj[pos], weight: gObj[pos], position: pos });
      this.goals = goals;
    }

    this.svgConnGoals$.next(
      this.sanitizer.bypassSecurityTrustHtml(
        this.core.getShootingFieldImg(
          {
            goals: this.goals,
            connections: this.connections,
          },
          "#48bbf8"
        )
      )
    );
  }

  playerColumns = [];
  public selectedPlayersColumns = [];

  public onDownloadPlayersCSVClick() {
    const columnsToExport = [];
    this.teamPlayers$Last.forEach(game => {
      const playerForExport = {};
      this.selectedPlayersColumns.forEach(col => {
        playerForExport[col.prop] = game[col.prop];
      });
      columnsToExport.push(playerForExport);
    });
    this.csvDownloadService.exportArrayOfObjectsToCSV(columnsToExport, 'Players');
  }

  public get selectedType() {
    return this.confData ? this.confData.mode : undefined;
  }

  public onGoalConnectionOffDefClick() {
    this.goalConnectionOffenseVisible = !this.goalConnectionOffenseVisible;
  }

  get isGameSystemEnabled(): boolean {
    return (
      this.core.gameDataService.gameSystems$.value.length > 0 &&
      this.core.gameDataService.gameSystemEnabled$.value
    );
  }
  public getRowClass = (row: any) => {
    if (row.row.colorCode === "red") {
      return {
        "red-row-color": true,
      };
    } else if (row.row.colorCode === "blue") {
      return {
        "blue-row-color": true,
      };
    } else if (row.row.colorCode === "yellow") {
      return {
        "yellow-row-color": true,
      };
    } else {
      return {
        "green-row-color": true,
      };
    }
  };

  public cOptions: any = {
    title: {
      text: "SAVES / GOALS %",
      display: true,
      fontSize: 20,
      fontColor: "#0e375f",
    },
    plugins: {
      labels: {
        fontSize: 14,
        fontColor: "#0e375f",
        render: (args: { value: number; percentage: number; label: string }) =>
          `${args.value} [${args.percentage}%]`,
      },
    },
    legend: {
      display: false,
    },
  };

  public c2Options: any = {
    title: {
      text: "GOALS / NO GOALS %",
      display: true,
      fontSize: 20,
      fontColor: "#0e375f",
    },
    plugins: {
      labels: {
        fontSize: 14,
        fontColor: "#0e375f",
        render: (args: { value: number; percentage: number; label: string }) =>
          `${args.value} [${args.percentage}%]`,
      },
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const datasetLabel = data.labels[tooltipItem.index];
          const datasetValue = data.datasets[0].data[tooltipItem.index];

          const dataset = data.datasets[0];
          const value = dataset.data[tooltipItem.index] as number;
          const dataValues: number[] = dataset.data as number[];

          const totalDataValue = dataValues.reduce((sum, currentValue) => sum + currentValue, 0);
          const percentage = (value / totalDataValue) * 100;

          return `${datasetLabel}: ${datasetValue} [${percentage.toFixed(0)}%]`;
        }
      }
    },
    legend: {
      display: false,
    },
  };
  public circleColors: Color[] = [{ backgroundColor: ["#ECB22E", "#2EB67D"] }];

  // pdf options
  public cOptions_PDF: any = {
    title: {
      text: toUpperCase("SAVES / GOALS %%"),
      display: false,
      fontSize: 20,
      fontColor: "#0e375f",
    },
    plugins: {
      labels: {
        fontSize: 48,
        fontWeight: 'bold',
        fontColor: "#fff",
        render: (args: { value: number; percentage: number; label: string }) =>
           `${args.value} [${args.percentage}%]`,
      },
    },
    legend: {
      display: false,
    },
  };

  public c2Options_PDF: any = {
    title: {
      text: toUpperCase("GOALS / NO GOALS %"),
      display: false,
      fontSize: 20,
      fontColor: "#0e375f",
    },
    plugins: {
      labels: {
        fontSize: 48,
        fontWeight: 'bold',
        fontColor: "#fff",
        render: (args: { value: number; percentage: number; label: string }) =>
          `${args.value} [${args.percentage}%]`,
      },
    },
    legend: {
      display: false,
    },
  };
  public circleColors_PDF: Color[] = [{ backgroundColor: ['#36ABE0', '#0E375F'] }];
  // end of pdf options

  public pieChartOptions: ChartOptions = {
    responsive: true,
    title: { text: "Goals Phase", display: true },
    legend: {
      position: "bottom",
    },
    plugins: {
      subtitle: { text: "In which Phase they score the most?", display: true },
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    },
  };

  public pieChartLabels: Label[] = [
    ["Positional"],
    ["Fastbreak"],
    ["Contragol"],
  ];
  public pieChartData: number[] = [300, 500, 100];
  public pieChartType: ChartType = "pie";
  public pieChartLegend = true;
  public pieChartColors = [
    {
      backgroundColor: ["#e64115", "#1e78ff", "#e0ad07"],
    },
  ];

  legend = [];

  totalAvg = [];
  public offenseEventsCols = [];
  public offenseEventsValCols = [];
  public offenseEventsViewShown = true;
  public offenseEventsEqualitySuperiorityViewShown = true;

  public playedTimeCols = [];
  public playedTimeRows = [];

  overview = {
    numberGamesPlayed: "00",
    efficency: "00.0",
    shotEfficiency: "00.0",
    lostBalls: "00.0",
    totalPlayerScore: "0.00",
    totalPlusMinus: "0.00",
  };

  columnsT1 = [];
  rowsT1 = [];
  counters = [];

  phasesColumns = [
    {
      prop: "template",
      name: "",
      resizable: false,
      sortable: false,
      frozenLeft: true,
      flexGrow: 2,
    },
  ];

  phasesRows = [];

  gameSystemColumns = [];

  private async transformPaceAndPhaseRows(itm: PaceAndPhaseDto[]) {
    this.phasesRows = [
      { template: await this.tg(_("Pace")) },
      { template: await this.tg(_("Total Goals")) },
      { template: await this.tg(_("Total Saves")) },
      { template: await this.tg(_("Total Lost Balls")) },
      { template: await this.tg(_("% Eff IN OFFENSE POSITIONAL")) },
      { template: await this.tg(_("% EFF IN FASTBREAK")) },
      { template: await this.tg(_("% EFF IN COUNTERGOAL")) },
      { template: await this.tg(_("% EFFICIENCY")) },
      { template: await this.tg(_("% SHOOTING EFFICIENCY")) },
      { template: await this.tg(_("% LOST BALLS EFFICIENCY")) },
      { template: await this.tg(_("LINK TO THE GAME")) },
    ];

    const rowProps = [
      "pace",
      "totalGoals",
      "totalSaves",
      "totalLostBalls",
      "effOffensePositional",
      "effFastBreak",
      "effCounterGoal",
      "efficiency",
      "shootingEfficiency",
      "lostBallEfficiency",
    ];
    const extraProps = ["link"];
    const allProps = [...rowProps, ...extraProps];

    let gameIndex = 0;
    for (let game of itm) {
      const obj = {
        link:
          "<a href='/account/" +
          (this.confData?.aeOverride ??
            this.core.accountEquipoService.selectedAccountEquipo$.getValue()
              .id) +
          "/game/" +
          game.gameId +
          "/stats'>Go to game</a>",
      };
      for (const prop of rowProps) {
        obj[prop] = game[prop];
      }

      // Overrides
      for (const prop of rowProps) {
        let value: any;
        if (prop === "pace") {
          value = (+obj[prop]).toFixed(1);
        } else if (
          prop === "totalGoals" ||
          prop === "totalSaves" ||
          prop === "totalLostBalls"
        ) {
          value = +obj[prop];
        } else {
          value = (+obj[prop]).toFixed(1) + " %";
        }
        obj[prop] = value;
      }
      for (let i = 0; i < allProps.length; i++) {
        const prop = allProps[i];
        if (this.phasesRows[i]) {
          this.phasesRows[i]["game" + gameIndex] = obj[prop];
        }
      }
      gameIndex++;
    }

    return this.phasesRows;
  }

  public defenseEventsViewClick() {
    this.offenseEventsViewShown = !this.offenseEventsViewShown;
  }

  public defenseEventsEqualitySuperioriotyViewClick() {
    this.offenseEventsEqualitySuperiorityViewShown = !this.offenseEventsEqualitySuperiorityViewShown;
  }

  public onExpandPacePhasesTableColumnsClick() {
    this.pacePhasesTableColsShown = !this.pacePhasesTableColsShown;
    if (!this.pacePhasesTableColsShown) {
      this.last6GamesColumns$Last = this.last6GamesColumns.slice(0, 3);
    } else {
      this.last6GamesColumns$Last = [...this.last6GamesColumns];
    }
  }

  public async onExpandPlayersTableColumnsClick() {
    this.playersTableColsShown = !this.playersTableColsShown;
    const playerColumnsWithoutSorting = await getPlayerColumnsForTeams(this.selectedType === 'LITE_MODE', this.tg);
    if (!this.playersTableColsShown) {
      this.selectedPlayersColumns = playerColumnsWithoutSorting.slice(0, 3);
    } else {
      this.selectedPlayersColumns = playerColumnsWithoutSorting.slice(0, 16);
    }
  }

  public onAccordionOverviewToggle(isOpen: boolean): void {
    this.overviewAccordionOpen = isOpen;
  }

  public onAccordionOffDefToggle(isOpen: boolean): void {
    this.offDefAccordionOpen = isOpen;
  }

  public onAccordionTeamEventsToggle(isOpen: boolean): void {
    this.teamEventsAccordionOpen = isOpen;
  }

  public onAccordionPaceAndPhasesToggle(isOpen: boolean): void {
    this.paceAndPhasesAccordionOpen = isOpen;
  }

  public onAccordionPlayersToggle(isOpen: boolean): void {
    this.playersAccordionOpen = isOpen;
  }

  public onAccordionGameSystemToggle(isOpen: boolean): void {
    this.gameSystemAccordionOpen = isOpen;
  }

  public onExpandAllClick() {
  this.overviewAccordionOpen = true;
  this.offDefAccordionOpen = true;
  this.teamEventsAccordionOpen = true;
  this.paceAndPhasesAccordionOpen = true;
  this.playersAccordionOpen = true;
  this.gameSystemAccordionOpen = true;
  }

  public onCollapseAllClick() {
    this.overviewAccordionOpen = false;
    this.offDefAccordionOpen = false;
    this.teamEventsAccordionOpen = false;
    this.paceAndPhasesAccordionOpen = false;
    this.playersAccordionOpen = false;
    this.gameSystemAccordionOpen = false;
  }

  public async onExpandGameSystemTableColumnsClick() {
    this.gameSystemTableColsShown = !this.gameSystemTableColsShown;
    const gameSystemCols = await gameSystemColumns(this.tg);
    if (!this.gameSystemTableColsShown) {
      this.gameSystemColumns = gameSystemCols.slice(0, 4);
    } else {
      this.gameSystemColumns = gameSystemCols;
    }
  }

  public async onExpandsuperiorityEqualityTableColumnsClick() {
    this.superiorityEqualityTableColsShown = !this.superiorityEqualityTableColsShown;
    if (!this.superiorityEqualityTableColsShown) {
      this.offenseEventsCols = [
        {prop: "phase", name: await this.tg(_("Equality / Superiority")), frozenLeft: true, flexGrow: 1, width: 130},
        {prop: "numOfPossessions", name: await this.tg(_("Nº Posessions")), flexGrow: 1, width: 75},
        {prop: "numOfGoals", name: await this.tg(_("Nº Goals")), flexGrow: 1, width: 75},
        {prop: "numOfShots", name: await this.tg(_("Nº Shots")), flexGrow: 1, width: 75}
      ];
    } else {
      this.offenseEventsCols = [
        {prop: "phase", name: await this.tg(_("Equality / Superiority")), frozenLeft: true, flexGrow: 1, width: 130},
        {prop: "numOfPossessions", name: await this.tg(_("Nº Posessions")), flexGrow: 1, width: 80},
        {prop: "numOfGoals", name: await this.tg(_("Nº Goals")), flexGrow: 1, width: 80},
        {prop: "numOfShots", name: await this.tg(_("Nº Shots")), flexGrow: 1, width: 80},
        {prop: "numOfLostBalls", name: await this.tg(_("Nº Lost Balls")), flexGrow: 1, width: 80},
        {prop: "eff", name: await this.tg(_("EFF %")), percentage: true, flexGrow: 1, width: 80},
        {prop: "shooting", name: await this.tg(_("Shooting %")), percentage: true, flexGrow: 1, width: 80},
        {prop: "lostBallEff", name: await this.tg(_("Lost Ball Eff %")), percentage: true, flexGrow: 1, width: 80},
      ];
    }
  }

  public async onExpandOffenseDefenseTableColumnsClick() {
    this.offenseDefenseTableColsShown = !this.offenseDefenseTableColsShown;
    if (!this.offenseDefenseTableColsShown) {
      this.offenseEventsValCols = [
        {prop: "phase", name: await this.tg(_("Phase")), flexGrow: 1, frozenLeft: true, width: 130},
        {prop: "goals", name: await this.tg(_("Goals")), flexGrow: 1, width: 75},
        {prop: "postOuts", name: await this.tg(_("Post Outs")), flexGrow: 1, width: 75},
        {prop: "saves", name: await this.tg(_("Saves")), flexGrow: 1, width: 75},
      ];
    } else {
      this.offenseEventsValCols = [
        {prop: "phase", name: await this.tg(_("Phase")), flexGrow: 1, frozenLeft: true, width: 130},
        {prop: "goals", name: await this.tg(_("Goals")), flexGrow: 1, width: 80},
        {prop: "postOuts", name: await this.tg(_("Post Outs")), flexGrow: 1, width: 80},
        {prop: "saves", name: await this.tg(_("Saves")), flexGrow: 1, width: 80},
        {prop: "lostBalls", name: await this.tg(_("Lost Balls")), flexGrow: 1, width: 80},
        {prop: "possessions", name: await this.tg(_("Possessions")), flexGrow: 1, width: 80},
        {prop: "eff", name: await this.tg(_("Efficiency %")), percentage: true, flexGrow: 1, width: 80},
        {prop: "shootingEff", name: await this.tg(_("Shooting Efficiency %")), percentage: true, flexGrow: 1, width: 80},
        {prop: "lostBallsEff", name: await this.tg(_("Lost Balls %")), percentage: true, flexGrow: 1, width: 80},
      ];
    }
  }

  private transformPaceAndPhaseColumns(itm: PaceAndPhaseDto[]) {
    let gameIndex = 0;
    return [
      {
        prop: "template",
        name: "",
        resizable: false,
        sortable: false,
        frozenLeft: true,
        flexGrow: 2,
      },
      ...itm.map((game) => {
        return {
          prop: "game" + gameIndex++,
          name: game.homeTeam + " vs " + game.visitorTeam + `- ${game.result}`,
          resizable: false,
          sortable: false,
          flexGrow: 1,
        };
      }),
    ];
  }

  public print() {
    if (this.printData) {
      const value = {
        teams: this.printData.teams,
        selectedTeam: this.printData.selectedTeam,
        selectedTeamData: this.printData.selectedTeamData,
        selectedGames: this.printData.selectedGames
      };
      this.printPdf(value);
    }
  }

  public printGkReport() {
    if (this.printData) {
      const value = {
        teams: this.printData.teams,
        selectedTeam: this.printData.selectedTeam,
        selectedTeamData: this.printData.selectedTeamData,
        selectedGames: this.printData.selectedGames,
        goalkeeperReport: true
      };
      this.printPdf(value);
    }
  }
}





// [rows]="gameSystemStatsView | async"
//             [columns]="gameSystemColumns"



/*
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import {
  Connection,
  CounterModel,
  Goals,
  QuickStatsColumnModel,
} from "@handballai/stats-calculation";
import { LoadingController, ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { ChartOptions, ChartType } from "chart.js";
import { Color, Label } from "ng2-charts";
import { NGXLogger } from "ngx-logger";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { tap, map, filter, concatMap } from "rxjs/operators";
import {
  GameListFilterDto,
  PaceAndPhaseDto,
  PlayerStatsAggregateDto,
  TeamStatsAggregateDto,
} from "src/app/api/hai-api";
import { CoreService } from "src/app/shared-services/core.service";
import { GameSystemStatsViewModel } from "src/app/shared-services/statistics/playbyplay/consumer/game-system/model/game-system-view.model";
import { createTeamPdf } from "../../pages/aehandler-module/pages/dashboard-module/components/team-analysis/team-pdf.helper";
import {
  shotsMap,
  eventTableLeftMap,
  eventTableRightMap,
} from "../../pages/aehandler-module/pages/dashboard-module/dashboard-handler";
import { PlayersTotalGameModel } from "../../pages/aehandler-module/pages/dashboard-module/dashboard.model";
import { transformGoalConnectionCounter } from "../../pages/aehandler-module/pages/dashboard-module/goal-connection-helper";
import {
  transformTotalTeamQuickStats,
  transformAvgTeamQuickStats,
  playersTableMap,
  transformPlayByPlayToViewModel,
  gameSystemColumns,
  getPlayerColumns,
} from "../../pages/aehandler-module/pages/dashboard-module/team-quick-stats.helper";
export interface DashTeamConf {
  mode: "COMPLETE_MODE" | "LITE_MODE";
  aeOverride?: number;
}
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: "app-dash-team-stats",
  templateUrl: "./dash-team-stats.component.html",
  styleUrls: ["./dash-team-stats.component.scss"],
})
export class DashTeamStatsComponent implements OnInit,OnDestroy {
  confData: DashTeamConf;
  @Input() set conf(value: DashTeamConf) {
    if (!value) return;
    this.confData = value;
    // TODO: Maybe we should move here service method calls to get new subject on every conf change
  }
  @Input() set printConf(value: any) {
    if (!value) return;
    if (
      this.teamAggregatedTbl3$Last == undefined ||
      this.teamAggregatedTbl4$Last == undefined ||
      this.last6GamesColumns$Last == undefined ||
      this.last6GamesRows$Last == undefined ||
      this.teamPlayers$Last == undefined ||
      this.totalQuickStats$Last == undefined ||
      this.totalQuickStats$Last == undefined ||
      this.total7MetersGoals$Last == undefined ||
      this.total7MetersFailedShoots$Last == undefined ||
      this.avgQuickStats$Last == undefined ||
      this.avg7MetersGoals$Last == undefined ||
      this.avg7MetersFailedShoots$Last == undefined
    )
      return;

    const printOptions = {
      teamEventsTbl3: this.teamAggregatedTbl3$Last,
      teamEventsTbl4: this.teamAggregatedTbl4$Last,
      paceAndPhaseColumns: this.last6GamesColumns$Last,
      paceAndPhase: this.last6GamesRows$Last,
      playersTbl: this.teamPlayers$Last,
      totalQuickStats: this.totalQuickStats$Last,
      teams: value.teams,
      quickStats: {
        totalQuickStats: this.totalQuickStats$Last || null,
        total7MetersGoals: this.total7MetersGoals$Last || 0,
        total7MetersFailedShoots: this.total7MetersFailedShoots$Last || 0,
        avgQuickStats: this.avgQuickStats$Last || null,
        avg7MetersGoals: this.avg7MetersGoals$Last || 0,
        avg7MetersFailedShoots: this.avg7MetersFailedShoots$Last || 0,
      },
      gameType: this.selectedType,
      gameSystemTbl: this.gameSystemStatsViewLast,
    };
    createTeamPdf(
      printOptions,
      this.loadingCtrl,
      this.translateService,
      this.modalCtrl,
      value.selectedTeam
    );
  }

  @Input() set season(season: GameListFilterDto[]) {
    if (!season) return;
    this.last6GamesColumns$ = this.core.dashboardsService.teamLast6$.pipe(
      map((columns) => {
        const filteredColumns =
          season.length === 0
            ? columns
            : columns.filter((column) =>
                season.find((izm) => izm.gameId === column.gameId)
              );
        return this.transformPaceAndPhaseColumns(filteredColumns);
      })
    );
    this.last6GamesRows$ = this.core.dashboardsService.teamLast6$.pipe(
      concatMap((rows) => {
        const filteredRows =
        season.length === 0
          ? rows
          : rows.filter((column) =>
              season.find((row) => row.gameId === column.gameId)
            );
        return this.transformPaceAndPhaseRows(filteredRows);
      }),
    );

    this.subscriptions.push(
      this.last6GamesColumns$.subscribe(
        (d) => (this.last6GamesColumns$Last = d)
      ),
      this.last6GamesRows$.subscribe((d) => (this.last6GamesRows$Last = d)),
    );
  }

  public translationsInited$ = new BehaviorSubject<boolean>(false);

  subscriptions: Subscription[];
  constructor(
    private readonly logger: NGXLogger,
    private readonly core: CoreService,
    private readonly sanitizer: DomSanitizer,
    private readonly loadingCtrl: LoadingController,
    private readonly modalCtrl: ModalController,
    private readonly translateService: TranslateService
  ) {
    core.dashboardsService.resetAll();
    this.teamAggregated$ = core.dashboardsService.teamAggregated$;
    this.shots$ = core.dashboardsService.teamAggregated$.pipe(
      tap((ind) => this.generateSvgConnGoals(ind)),
      map((ind) => shotsMap(ind))
    );
    this.goalConnection$ = core.dashboardsService.teamAggregated$.pipe(
      map((dto) => transformGoalConnectionCounter(dto))
    );
    this.totalQuickStats$ = core.dashboardsService.teamAggregated$.pipe(
      map((dto) => transformTotalTeamQuickStats(dto))
    );
    this.avgQuickStats$ = core.dashboardsService.teamAggregated$.pipe(
      map((dto) => transformAvgTeamQuickStats(dto))
    );
    this.total7MetersGoals$ = core.dashboardsService.teamAggregated$.pipe(
      map((dto) => +dto?.totalGoals7Meters)
    );
    this.total7MetersFailedShoots$ =
      core.dashboardsService.teamAggregated$.pipe(
        map((dto) => +dto?.totalShots7Meters - +dto?.totalGoals7Meters)
      );
    this.avg7MetersGoals$ = core.dashboardsService.teamAggregated$.pipe(
      map((dto) => +dto?.avgGoals7Meters)
    );
    this.avg7MetersFailedShoots$ = core.dashboardsService.teamAggregated$.pipe(
      map((dto) => +dto?.avgShots7Meters - +dto?.avgGoals7Meters)
    );
    this.totalGoalsFailedShots$ = core.dashboardsService.teamAggregated$.pipe(
      map((dto) =>
        !dto
          ? [1, 1]
          : [
              +dto.totalGkReceivedOffensePositional +
                +dto.totalGkReceivedCounterGoal +
                +dto.totalGkReceivedFastBreak,
              +dto.totalOpponentFailedShots,
            ]
      )
    );

    this.totalGoalsSaves$ = core.dashboardsService.teamAggregated$.pipe(
      map((dto) =>
        !dto
          ? [1, 1]
          : [
              +dto.totalGkReceivedOffensePositional +
                +dto.totalGkReceivedCounterGoal +
                +dto.totalGkReceivedFastBreak,
              +dto.totalGkSaveOffensePositional +
                +dto.totalGkSaveFastBreak +
                +dto.totalGkSaveCounterGoal,
            ]
      )
    );
    this.teamAggregatedTbl1$ = core.dashboardsService.teamAggregated$.pipe(
      map(async (ind) => {
        if (!ind) {
          return [];
        }
        return [
          // {
          //   template: "Time Played",
          //   total: timerFormatHelper(+ind.totalTime),
          //   average: timerFormatHelper(+ind.avgTime),
          // },
          {
            template: await this.tg(_("Possesions")),
            // template: await this.tg(_("Possesions")),
            total: ind.totalPossessions,
            average: ind.avgPossessions,
          },
          {
            template: await this.tg(_("Goals")),
            total: ind.totalGoals,
            average: ind.avgGoals,
          },
          {
            template: await this.tg(_("Saves")),
            total: ind.totalSaves,
            average: ind.avgSaves,
          },
          {
            template: await this.tg(_("Fail Shots")),
            total:
              +ind.totalFailedShotsCounterGoal +
              +ind.totalFailedShotsFastBreak +
              +ind.totalFailedShotsOffensePositional,
            average:
              +ind.avgFailedShotsCounterGoal +
              +ind.avgFailedShotsFastBreak +
              +ind.avgFailedShotsOffensePositional,
          },
          {
            template: await this.tg(_("Post Outs")),
            total:
              +ind.totalPostOutCounterGoal +
              +ind.totalPostOutFastBreak +
              +ind.totalPostOutOffensePositional,
            average:
              +ind.avgPostOutCounterGoal +
              +ind.avgPostOutFastBreak +
              +ind.avgPostOutOffensePositional,
          },
        ];
      })
    );
    this.teamAggregatedTbl2$ = core.dashboardsService.teamAggregated$.pipe(
      map(async (ind) => {
        if (!ind) {
          return [];
        }
        return [
          {
            playerInfo: await this.tg(_("Total Goals")),
            offensePositional: ind.totalGoalsOffensePositional,
            fastBreak: ind.totalGoalsFastBreak,
            counterGoal: ind.totalGoalsCounterGoals,
          },
          {
            playerInfo: await this.tg(_("Total Saves")),
            offensePositional: ind.totalSavesOffensePositional,
            fastBreak: ind.totalSavesFastBreak,
            counterGoal: ind.totalSavesCounterGoals,
          },
          {
            playerInfo: await this.tg(_("Total Lost Balls")),
            offensePositional: ind.totalLostBallsOffensePositional,
            fastBreak: ind.totalLostBallsFastBreak,
            counterGoal: ind.totalLostBallsCounterGoals,
          },
        ];
      })
    );
    this.teamAggregatedTbl3$ = core.dashboardsService.teamAggregated$.pipe(
      concatMap((ind) => eventTableLeftMap(ind, this.tg))
    );
    this.teamAggregatedTbl4$ = core.dashboardsService.teamAggregated$.pipe(
      concatMap((ind) => eventTableRightMap(ind, this.tg))
    );
    this.teamPlayers$ = core.dashboardsService.teamPlayers$.pipe(
      tap((ind) => this.generateSvgConnGoals(null, ind)),
      map((team) => playersTableMap(team, this.selectedType === "LITE_MODE"))
    );
    this.gameSystemStatsView = core.dashboardsService.filteredPlayByPlay$.pipe(
      map((itm) => transformPlayByPlayToViewModel(itm))
    );

    this.subscriptions = [
      this.teamAggregatedTbl3$.subscribe(
        (d) => (this.teamAggregatedTbl3$Last = d)
      ),
      this.teamAggregatedTbl4$.subscribe(
        (d) => (this.teamAggregatedTbl4$Last = d)
      ),
      this.teamPlayers$.subscribe((d) => (this.teamPlayers$Last = d)),
      this.totalQuickStats$.subscribe((d) => (this.totalQuickStats$Last = d)),
      this.total7MetersGoals$.subscribe(
        (d) => (this.total7MetersGoals$Last = d)
      ),
      this.total7MetersFailedShoots$.subscribe(
        (d) => (this.total7MetersFailedShoots$Last = d)
      ),
      this.avgQuickStats$.pipe(filter((d)=>!!d)).subscribe((d) => ( this.avgQuickStats$Last = Object.keys(d).reduce((memo, key)=> {
          memo[key] = isNaN(d[key]) ? d[key] : d[key] % 1 === 0 ? d[key] : parseFloat(d[key].toFixed(1))
          return memo;
        },{})
      )),
      this.avg7MetersGoals$.subscribe((d) => (this.avg7MetersGoals$Last = d)),
      this.avg7MetersFailedShoots$.subscribe(
        (d) => (this.avg7MetersFailedShoots$Last = d)
      ),
      this.gameSystemStatsView.subscribe(
        (d) => (this.gameSystemStatsViewLast = d)
      ),
    ];
  }
  async ngOnInit(): Promise<void> {
    this.gameSystemColumns = await gameSystemColumns(this.tg);
    this.playerColumns = await getPlayerColumns(this.selectedType === 'LITE_MODE', this.tg);

    this.cOptions.title.text = await this.tg(_("SAVES / GOALS %"));
    this.c2Options.title.text = await this.tg(_("GOALS / NO GOALS %"));
    this.pieChartOptions.title.text = await this.tg(_("Goals Phase"));
    this.pieChartOptions.plugins.subtitle.text = await this.tg(_("In which Phase they score the most?"));

    this.legend = [
      { key: "eff", desc: await this.tg(_("% Efficiency")), value: "00.0%" },
      { key: "shot", desc: await this.tg(_("% Shot Efficiency")), value: "00.0%" },
      { key: "losts", desc: await this.tg(_("% Losts Balls")), value: "00.0%" },
    ];
    this.totalAvg = [
      { prop: "template", name: await this.tg(_("Events")), resizable: false, sortable: true, frozenLeft: true, flexGrow: 2 },
      { prop: "total", name: await this.tg(_("Total")), flexGrow: 1 },
      { prop: "average", name: await this.tg(_("Average")), flexGrow: 1 },
    ];
    this.columnsT1 = [
      { prop: "template", name: "", resizable: false, sortable: false, frozenLeft: true, flexGrow: 1 },
      { prop: "total", name: await this.tg(_("Total")), flexGrow: 1 },
      { prop: "average", name: await this.tg(_("Average")), flexGrow: 1 },
    ];

    this.rowsT1 = [
      { template: await this.tg(_("Time Played")), total: "00", average: "00:00" },
      { template: await this.tg(_("Possesions")), total: "00", average: "00:00" },
      { template: await this.tg(_("Goals")), total: "00", average: "00:00" },
      { template: await this.tg(_("Saves")), total: "00", average: "00:00" },
      { template: await this.tg(_("Lost Balls")), total: "00", average: "00:00" },
      { template: await this.tg(_("Steal Balls")), total: "00", average: "00:00" },
      { template: await this.tg(_("Shot Balls")), total: "00", average: "00:00" },
    ];

    this.counters = [
      { prop: "playerInfo", name: "", resizable: false, sortable: false, frozenLeft: true, flexGrow: 1 },
      { prop: "offensePositional", name: await this.tg(_("Offense Positional")), flexGrow: 1 },
      { prop: "counterGoal", name: await this.tg(_("Counter Goal")), flexGrow: 1 },
      { prop: "fastBreak", name: await this.tg(_("Fastbreak")), flexGrow: 1 },
    ];
    this.phasesRows = [
      { template: await this.tg(_("Pace")) },
      { template: await this.tg(_("Total Goals")) },
      { template: await this.tg(_("Total Saves")) },
      { template: await this.tg(_("Total Lost Balls")) },
      { template: await this.tg(_("% Eff IN OFFENSE POSITIONAL")) },
      { template: await this.tg(_("% EFF IN FASTBREAK")) },
      { template: await this.tg(_("% EFF IN COUNTERGOAL")) },
      { template: await this.tg(_("% EFFICIENCY")) },
      { template: await this.tg(_("% SHOOTING EFFICIENCY")) },
      { template: await this.tg(_("% LOST BALLS EFFICIENCY")) },
      { template: await this.tg(_("LINK TO THE GAME")) },
    ];

    this.translationsInited$.next(true);
  }

  tg = (def: string, skipLangs: string[] = []) => {
    return this.core.i18nService.tg(def, skipLangs);
  };

  ngOnDestroy(): void {}

  // teams$: Observable<EntityFilterDto[]>;
  // seasonOptions$: Observable<GameListFilter[]>;
  teamAggregated$: Observable<TeamStatsAggregateDto>;
  teamAggregatedTbl1$;
  teamAggregatedTbl2$;
  shots$: Observable<CounterModel[]>;
  goalConnection$: Observable<CounterModel[]>;
  totalGoalsFailedShots$: Observable<number[]>;
  totalGoalsSaves$: Observable<number[]>;
  gameSystemStatsView: Observable<GameSystemStatsViewModel[]>;

  teamAggregatedTbl3$: Observable<{
    average?: any;
    template: any;
    total: string | number;
  }[]>;
  teamAggregatedTbl3$Last;
  teamAggregatedTbl4$;
  teamAggregatedTbl4$Last;
  last6GamesColumns$;
  last6GamesColumns$Last;
  last6GamesRows$;
  last6GamesRows$Last;
  teamPlayers$: Observable<PlayersTotalGameModel[]>;
  teamPlayers$Last;
  totalQuickStats$: Observable<QuickStatsColumnModel>;
  totalQuickStats$Last;
  total7MetersGoals$: Observable<number>;
  total7MetersGoals$Last;
  total7MetersFailedShoots$: Observable<number>;
  total7MetersFailedShoots$Last;
  avgQuickStats$: Observable<QuickStatsColumnModel>;
  avgQuickStats$Last;
  avg7MetersGoals$: Observable<number>;
  avg7MetersGoals$Last;
  avg7MetersFailedShoots$: Observable<number>;
  avg7MetersFailedShoots$Last;
  gameSystemStatsViewLast;

  svgConnGoals$: BehaviorSubject<SafeHtml> = new BehaviorSubject<SafeHtml>(
    null
  );
  connections: Connection[] = [];
  goals: Goals[] = [];
  async generateSvgConnGoals(
    teamAggregated: TeamStatsAggregateDto = null,
    playerStats: PlayerStatsAggregateDto[] = null
  ) {
    // if (!teamAggregated) { return; }
    // const subject = (isSecond) ? this.svgConnGoals2$ : this.svgConnGoals1$;

    if (teamAggregated) {
      const possitions = ["Gk", "Lw", "Rw", "Lb", "Rb", "Cb", "Lp"];
      const connections: Connection[] = [];
      for (let pos_a of possitions)
        for (let pos_b of possitions)
          if (pos_a != pos_b) {
            const value = +(await teamAggregated)[
              "totalAssist" + pos_a + pos_b
            ];
            if (value)
              connections.push({
                startPos: pos_a.toLowerCase(),
                endPos: pos_b.toLowerCase(),
                weight: value,
              });
          }
      this.connections = connections;
    }

    // TODO: Fetch from server or agregate from players
    if (playerStats) {
      const goals: Goals[] = [];
      const gObj = (await playerStats).reduce(
        (base, p) => {
          base[p.playerPosition] += +p.totalGoals;
          return base;
        },
        {
          gk: 0,
          lw: 0,
          rw: 0,
          lb: 0,
          rb: 0,
          cb: 0,
          lp: 0,
        }
      );
      for (const pos in gObj)
        goals.push({ label: "" + gObj[pos], weight: gObj[pos], position: pos });
      this.goals = goals;
    }

    this.svgConnGoals$.next(
      this.sanitizer.bypassSecurityTrustHtml(
        this.core.getShootingFieldImg(
          {
            goals: this.goals,
            connections: this.connections,
          },
          "#48bbf8"
        )
      )
    );
  }

  playerColumns = [];

  public get selectedType() {
    return this.confData ? this.confData.mode : undefined;
  }
  get isGameSystemEnabled(): boolean {

    console.log('DASHBOARD game system this.core.gameDataService.gameSystems$.value', this.core.gameDataService.gameSystems$.value);

    return (
      this.core.gameDataService.gameSystems$.value.length > 0 &&
      this.core.gameDataService.gameSystemEnabled$.value
    );
  }
  public getRowClass = (row: any) => {
    if (row.row.colorCode === "red") {
      return {
        "red-row-color": true,
      };
    } else if (row.row.colorCode === "blue") {
      return {
        "blue-row-color": true,
      };
    } else if (row.row.colorCode === "yellow") {
      return {
        "yellow-row-color": true,
      };
    } else {
      return {
        "green-row-color": true,
      };
    }
  };

  public cOptions: any = {
    title: {
      text: "SAVES / GOALS %",
      display: true,
      fontSize: 20,
      fontColor: "#0e375f",
    },
    plugins: {
      labels: {
        fontSize: 14,
        fontColor: "#0e375f",
        render: (args: { value: number; percentage: number; label: string }) =>
          `${args.value} [${args.percentage}%]`,
      },
    },
    legend: {
      display: false,
    },
  };

  public c2Options: any = {
    title: {
      text: "GOALS / NO GOALS %",
      display: true,
      fontSize: 20,
      fontColor: "#0e375f",
    },
    plugins: {
      labels: {
        fontSize: 14,
        fontColor: "#0e375f",
        render: (args: { value: number; percentage: number; label: string }) =>
          `${args.value} [${args.percentage}%]`,
      },
    },
    legend: {
      display: false,
    },
  };
  public circleColors: Color[] = [{ backgroundColor: ["#ECB22E", "#2EB67D"] }];

  public pieChartOptions: ChartOptions = {
    responsive: true,
    title: { text: "Goals Phase", display: true },
    legend: {
      position: "bottom",
    },
    plugins: {
      subtitle: { text: "In which Phase they score the most?", display: true },
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    },
  };

  public pieChartLabels: Label[] = [
    ["Positional"],
    ["Fastbreak"],
    ["Contragol"],
  ];
  public pieChartData: number[] = [300, 500, 100];
  public pieChartType: ChartType = "pie";
  public pieChartLegend = true;
  public pieChartColors = [
    {
      backgroundColor: ["#e64115", "#1e78ff", "#e0ad07"],
    },
  ];

  legend = [];

  totalAvg = [];

  overview = {
    numberGamesPlayed: "00",
    efficency: "00.0",
    shotEfficiency: "00.0",
    lostBalls: "00.0",
    totalPlayerScore: "0.00",
    totalPlusMinus: "0.00",
  };

  columnsT1 = [];
  rowsT1 = [];
  counters = [];

  phasesColumns = [
    {
      prop: "template",
      name: "",
      resizable: false,
      sortable: false,
      frozenLeft: true,
      flexGrow: 2,
    },
  ];

  phasesRows = [];

  gameSystemColumns = [];

  private async transformPaceAndPhaseRows(itm: PaceAndPhaseDto[]) {
    this.phasesRows = [
      { template: await this.tg(_("Pace")) },
      { template: await this.tg(_("Total Goals")) },
      { template: await this.tg(_("Total Saves")) },
      { template: await this.tg(_("Total Lost Balls")) },
      { template: await this.tg(_("% Eff IN OFFENSE POSITIONAL")) },
      { template: await this.tg(_("% EFF IN FASTBREAK")) },
      { template: await this.tg(_("% EFF IN COUNTERGOAL")) },
      { template: await this.tg(_("% EFFICIENCY")) },
      { template: await this.tg(_("% SHOOTING EFFICIENCY")) },
      { template: await this.tg(_("% LOST BALLS EFFICIENCY")) },
      { template: await this.tg(_("LINK TO THE GAME")) },
    ];

    const rowProps = [
      "pace",
      "totalGoals",
      "totalSaves",
      "totalLostBalls",
      "effOffensePositional",
      "effFastBreak",
      "effCounterGoal",
      "efficiency",
      "shootingEfficiency",
      "lostBallEfficiency",
    ];
    const extraProps = ["link"];
    const allProps = [...rowProps, ...extraProps];

    let gameIndex = 0;
    for (let game of itm) {
      const obj = {
        link:
          "<a href='/account/" +
          (this.confData?.aeOverride ??
            this.core.accountEquipoService.selectedAccountEquipo$.getValue()
              .id) +
          "/game/" +
          game.gameId +
          "/stats'>Go to game</a>",
      };
      for (const prop of rowProps) {
        obj[prop] = game[prop];
      }

      // Overrides
      for (const prop of rowProps) {
        let value: any;
        if (prop === "pace") {
          value = (+obj[prop]).toFixed(1);
        } else if (
          prop === "totalGoals" ||
          prop === "totalSaves" ||
          prop === "totalLostBalls"
        ) {
          value = +obj[prop];
        } else {
          value = (+obj[prop]).toFixed(1) + " %";
        }
        obj[prop] = value;
      }
      for (let i = 0; i < allProps.length; i++) {
        const prop = allProps[i];
        if (this.phasesRows[i]) {
          this.phasesRows[i]["game" + gameIndex] = obj[prop];
        }
      }
      gameIndex++;
    }

    return this.phasesRows;
  }

  private transformPaceAndPhaseColumns(itm: PaceAndPhaseDto[]) {
    let gameIndex = 0;
    return [
      {
        prop: "template",
        name: "",
        resizable: false,
        sortable: false,
        frozenLeft: true,
        flexGrow: 2,
      },
      ...itm.map((game) => {
        return {
          prop: "game" + gameIndex++,
          name: game.homeTeam + " vs " + game.visitorTeam + `- ${game.result}`,
          resizable: false,
          sortable: false,
          flexGrow: 1,
        };
      }),
    ];
  }
}

*/
