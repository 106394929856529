<ion-card class="page-card">
  <div class="header-container" #headerSection>
    <ng-content select="div.header"></ng-content>
  </div>

  <div [ngClass]="foldersPage ? 'content-container-folders' : ''" class="content-container" #contentSection [style]="{maxHeight: 'calc(100% - '+contentMaxHeightOffset+'px)', minHeight: 'calc(100% - '+contentMaxHeightOffset+'px)'}">
    <div *ngIf="!items?.length" class="no-elements">
      {{('There is no elements in the list'|translate)}}
    </div>
    <ng-container *ngFor="let item of items">
      <div (click)="onItemClick(item, $event)" class="clickAllowedSource" *ngIf="!deletedFilterProp || !item[deletedFilterProp]">
        <ng-container [ngTemplateOutlet]="itemTemplate ? itemTemplate:itemFallbackTemplate" [ngTemplateOutletContext]="{item:item, selected: isSelected(item)}"></ng-container>
        <ng-template #itemFallbackTemplate let-item="item" let-selected="selected">
          <ion-item lines="full" [color]="selected?'alternative':''" class="item-default clickAllowedSource">
            <ion-avatar class="clickAllowedSource"><img class="clickAllowedSource" [src]="item.imageUrl || defaultItemImage"></ion-avatar>
            <ion-label class="clickAllowedSource">{{item.name}}</ion-label>
            <ng-container [ngTemplateOutlet]="itemTemplateExtra" [ngTemplateOutletContext]="{item:item, selected: selected}"></ng-container>
          </ion-item>
        </ng-template>
      </div>
    </ng-container>
  </div>

  <div class="footer-container" #footerSection>
    <ng-content select="div.footer"></ng-content>
  </div>
</ion-card>
