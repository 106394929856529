<div class="flex-auto content" *ngIf="(teamAggregated$|async) && (translationsInited$|async)">

  <div class="collapse-expand-txt">
    <span (click)="onExpandAllClick()" style="cursor: pointer;">
      {{ 'Expand All' | translate }}
    </span>

    <span style="margin: 0 15px">
      |
    </span>

    <span (click)="onCollapseAllClick()" style="cursor: pointer;">
      {{ 'Collapse All' | translate }}
    </span>
  </div>
  <app-dashboard-accordion
      [title]="'OVERVIEW'"
      [description]="'(Team Totals/Averages, Goalkeeper, Connection & Goals, Shooting Positions)'"
      [isOpen]="overviewAccordionOpen"
      (toggle)="onAccordionOverviewToggle($event)"
  >
    <div class="flex flex-col lg:flex-row w-full">
      <!---lEFT-->
      <div class="w-full flex-col pr-2" id="overview">
        <div class="quick-stats-wrapper">
          <app-quick-stats-table [showOneTeam]="false"
                                 [showDashBoard]="true"
                                 [showSaves]="true"
                                 [usePossessionEvents]="false"
                                 class="w-full"
                                 [homeQuickOverviewModel]="totalQuickStats$ | async"
                                 [home7MetersGoals]=" total7MetersGoals$ | async"
                                 [home7MetersFailedShoots]="total7MetersFailedShoots$ | async"
                                 [visitorQuickOverviewModel]="avgQuickStats$ | async"
                                 [visitor7MetersGoals]="avg7MetersGoals$ | async"
                                 [visitor7MetersFailedShots]="avg7MetersFailedShoots$ | async"
                                 >
            <div second-column-header>
              {{'TEAM TOTALS'|translate}}
            </div>
            <div third-column-header>
              {{'TEAM AVERAGE'|translate}}
            </div>
          </app-quick-stats-table>
        </div>

        <div class="w-full">
          <div class="w-full">
            <h2 class="select-label uppercase">
              {{ 'Connection & Goals' | translate }}
            </h2>
            <hr class="separator">
          </div>+
          <div id="team-connGoals" class="svg-container" [innerHTML]="svgConnGoals$ | async"></div>
        </div>
        <div class="flex flex-col mt-5 md:flex-row w-full">
          <ngx-datatable
              style="width: 100%; box-shadow: none"
              class="material"
              [rows]="playedTimeRows"
              [columns]="playedTimeCols"
              [columnMode]="'force'"
              [limit]="100"
              rowHeight="auto"
              [scrollbarH]="true"
              [footerHeight]="false"
              [headerHeight]="50"
          >
            <ngx-datatable-column *ngFor="let col of playedTimeCols"
                                  [prop]="col.prop"
                                  [width]="col.width"
                                  [sortable]="false"
                                  [resizeable]="col.resizeable"
                                  [headerClass]="col.frozenLeft ? 'column-left': ''">
              <ng-template ngx-datatable-header-template>
                <span *ngIf="!col?.frozenLeft">{{col.name}}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <ng-container *ngIf="col.frozenLeft">
                  {{value}}
                </ng-container>
                <span *ngIf="!col.frozenLeft">{{value}}</span>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>

      <!--RIGTH-->
      <div class="flex flex-col w-full vl" id="lastGames">
        <div class="w-full">
          <h2 class="select-label uppercase">
            {{ 'Goalkeeper Performance' | translate }}
          </h2>
          <hr class="separator">
        </div>

        <div class="w-full doughnut-outer-wrapper">
          <div class="doughnut-wrapper" id="team-doughnut1">
            <canvas baseChart
                    [legend]="true"
                    height="300px"
                    chartType="doughnut"
                    [data]="totalGoalsSaves$|async"
                    [labels]="['GOALS'|translate, 'SAVES'|translate]"
                    [options]="cOptions"
                    [colors]="circleColors">
            </canvas>
            <div class="w-full p-2 doughnut-legend">
              {{'Saves of the Goalkeepers / Goals received'|translate}}
            </div>
          </div>
          <div class="doughnut-wrapper" id="team-doughnut2">
            <canvas baseChart
                    [legend]="true"
                    height="300px"
                    chartType="doughnut"
                    [data]="totalGoalsFailedShots$|async"
                    [labels]="['GOALS'|translate, 'FAILED SHOTS'|translate]"
                    [options]="c2Options"
                    [colors]="circleColors">
            </canvas>
            <div class="w-full p-2 doughnut-legend">
              {{'Saves and Post Outs / Goals received'|translate}}
            </div>
          </div>
        </div>

        <!-- for PDF move to invisible zone -->
        <div class="w-full doughnut-outer-wrapper" style="position: absolute; left: -1999px; visibility: hidden">
          <div class="doughnut-wrapper" id="team-doughnut1_pdf">
            <canvas baseChart
                    [legend]="false"
                    height="300px"
                    chartType="doughnut"
                    [data]="totalGoalsSaves$|async"
                    [labels]="['GOALS'|translate, 'SAVES'|translate]"
                    [options]="cOptions_PDF"
                    [colors]="circleColors_PDF">
            </canvas>
            <div class="w-full p-2 doughnut-legend">
              {{'Saves of the Goalkeepers / Goals received'|translate}}
            </div>
          </div>
          <div class="doughnut-wrapper" id="team-doughnut2_pdf">
            <canvas baseChart
                    [legend]="false"
                    height="300px"
                    chartType="doughnut"
                    [data]="totalGoalsFailedShots$|async"
                    [labels]="['GOALS'|translate, 'FAILED SHOTS'|translate]"
                    [options]="c2Options_PDF"
                    [colors]="circleColors_PDF">
            </canvas>
            <div class="w-full p-2 doughnut-legend">
              {{'Saves and Post Outs / Goals received'|translate}}
            </div>
          </div>
        </div>
        <!-- end charts for PDF -->

        <div style="display: flex; justify-content: space-between">
          <span *ngIf="!core.isMobileDevice" style="width: 35%"></span>
          <h2 class="select-label uppercase" [style.width]="!core.isMobileDevice ? '35%' : '50%'" style="text-align: center">
            {{ goalConnectionOffenseVisible ? "Goals / Shoots Scored" : "Goals / Shoots received" | translate }}
          </h2>
          <div [style.width]="!core.isMobileDevice ? '35%' : ''">
            <button
                (click)="onGoalConnectionOffDefClick()"
                class="personalize-cols-button rounded-xl px-8 py-3"
            >
              {{ goalConnectionOffenseVisible ? 'Show Defense' : 'Show Offense' }}
            </button>
          </div>
        </div>
        <hr class="separator">
        <div class="w-full">
          <app-shooter-position *ngIf="goalConnectionOffenseVisible" id="acc-teamshooting"
                  [goalCounters]="shots$|async"
                  [goalConnectionCounter]="goalConnection$ | async | shotTransform"
                  class="w-full">
          </app-shooter-position>
          <app-shooter-position *ngIf="!goalConnectionOffenseVisible" id="acc-teamshooting"
                                [goalCounters]="shots$|async"
                                [goalConnectionCounter]="goalConnectionDef$ | async | shotTransform"
                                class="w-full">
          </app-shooter-position>
        </div>
        <div style="visibility: hidden;position: absolute; left: -1999px; display: flex; height: 400px;" class="w-full mt-4">
          <ng-container *ngFor="let position of availablePositions">
            <app-shooter-position id="acc-teamshooting-gk"
                                  [position]="position"
                                  [goalConnectionCounter]="goalConnection$ | async | shotTransform"
                                  class="w-full">
            </app-shooter-position>
          </ng-container>
        </div>
      </div>
    </div>
  </app-dashboard-accordion>

  <app-dashboard-accordion
      [title]="'OFFENSE/DEFENSE PERFORMANCE'"
      [description]="'(Events in the 3 Phases in Equality and Superiority)'"
      [isOpen]="offDefAccordionOpen"
      (toggle)="onAccordionOffDefToggle($event)"
  >
    <div *ngIf="offDefAccordionOpen" class="grid-col w-full">
      <div [style.justify-content]="core.isMobileDevice ? 'space-between' : ''" style="display: flex; margin-top: 30px">
        <h2 class="select-label-right" [style.width]="!core.isMobileDevice ? '60%' : ''">
          {{ offenseEventsEqualitySuperiorityViewShown ? "Offense Superiority / Equality events" : "Defense Superiority / Equality events" | translate }}
        </h2>
        <div class="offense-cont">
          <button
              (click)="defenseEventsEqualitySuperioriotyViewClick()"
              class="personalize-cols-button
                focus:outline-none focus:ring focus:border-blue-400
                active:bg-blue-800
                rounded-xl
                px-8 py-3
                capitalize
              "
          >
            {{ offenseEventsEqualitySuperiorityViewShown ? "Show Defense" : "Show Offense" | translate }}
          </button>
        </div>
      </div>
      <hr class="separator">
      <div *ngIf="core.isMobileDevice" class="expand-toggle">
        <ion-toggle (ionChange)="onExpandsuperiorityEqualityTableColumnsClick()"></ion-toggle>
      </div>
      <div class="flex flex-col md:flex-row w-full" *ngIf="offenseEventsEqualitySuperiorityViewShown">
        <ngx-datatable
            style="width: 100%; box-shadow: none"
            class="material"
            [rows]="offenseEventsRows"
            [columns]="offenseEventsCols"
            [columnMode]="'force'"
            [limit]="100"
            rowHeight="auto"
            [scrollbarH]="true"
            [footerHeight]="false"
            [headerHeight]="50"
        >
          <ngx-datatable-column *ngFor="let col of offenseEventsCols"
                                [prop]="col.prop"
                                [width]="col.width"
                                [sortable]="false"
                                [resizeable]="col.resizeable"
                                [headerClass]="col.frozenLeft ? 'column-left': ''">
            <ng-template ngx-datatable-header-template>
              <ng-container *ngIf="col.frozenLeft">{{col.name}}</ng-container>
              <span *ngIf="!col?.frozenLeft">{{col.name}}</span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <ng-container *ngIf="col.frozenLeft">
                {{value}}
              </ng-container>
              <span *ngIf="col.percentage && !col.frozenLeft">{{value | number: '1.0-0'}} %</span>
              <span *ngIf="!col.percentage && !col.frozenLeft">{{value}}</span>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
      <div class="flex flex-col md:flex-row w-full" *ngIf="!offenseEventsEqualitySuperiorityViewShown">
        <ngx-datatable
            style="width: 100%; box-shadow: none"
            class="material"
            [rows]="defenseEventsRows"
            [columns]="offenseEventsCols"
            [columnMode]="'force'"
            [limit]="100"
            rowHeight="auto"
            [scrollbarH]="true"
            [footerHeight]="false"
            [headerHeight]="50"
        >
          <ngx-datatable-column *ngFor="let col of offenseEventsCols"
                                [prop]="col.prop"
                                [width]="col.width"
                                [sortable]="false"
                                [resizeable]="col.resizeable"
                                [headerClass]="col.frozenLeft ? 'column-left': ''">
            <ng-template ngx-datatable-header-template>
              <ng-container *ngIf="col.frozenLeft">{{col.name}}</ng-container>
              <span *ngIf="!col?.frozenLeft">{{col.name}}</span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <ng-container *ngIf="col.frozenLeft">
                {{value}}
              </ng-container>
              <span *ngIf="col.percentage && !col.frozenLeft">{{value | number: '1.0-0'}} %</span>
              <span *ngIf="!col.percentage && !col.frozenLeft">{{value}}</span>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>

    <div *ngIf="offDefAccordionOpen" class="grid-col w-full">
      <div [style.justify-content]="core.isMobileDevice ? 'space-between' : ''" style="display: flex; margin-top: 30px">
        <h2 class="select-label-right" [style.margin-top]="core.isMobileDevice ? '25px' : ''" style="width: 54%">
          {{ offenseEventsViewShown ? "Offense events" : "Defense events" | translate }}
        </h2>
        <div class="offense-cont" [style.width]="!core.isMobileDevice ? '46%' : ''">
          <button
              (click)="defenseEventsViewClick()"
              class="personalize-cols-button
                focus:outline-none focus:ring focus:border-blue-400
                active:bg-blue-800
                rounded-xl
                px-8 py-3
                capitalize
              "
          >
            {{ offenseEventsViewShown ? "Show Defense" : "Show Offense" | translate }}
          </button>
        </div>
      </div>
      <hr class="separator">
      <div *ngIf="core.isMobileDevice" class="expand-toggle">
        <ion-toggle (ionChange)="onExpandOffenseDefenseTableColumnsClick()"></ion-toggle>
      </div>
      <div class="flex flex-col md:flex-row w-full" *ngIf="offenseEventsViewShown">
        <ngx-datatable
            style="width: 100%; box-shadow: none"
            class="material"
            [rows]="offenseEventsValRows"
            [columns]="offenseEventsValCols"
            [columnMode]="'force'"
            [limit]="100"
            rowHeight="auto"
            [scrollbarH]="true"
            [footerHeight]="false"
            [headerHeight]="50"
        >
          <ngx-datatable-column *ngFor="let col of offenseEventsValCols"
                                [prop]="col.prop"
                                [width]="col.width"
                                [sortable]="false"
                                [resizeable]="col.resizeable"
                                [headerClass]="col.frozenLeft ? 'column-left': ''">
            <ng-template ngx-datatable-header-template>
              <ng-container  *ngIf="col.frozenLeft">{{col.name}}</ng-container>
              <span *ngIf="!col?.frozenLeft">{{col.name}}</span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <ng-container *ngIf="col.frozenLeft">
                {{value}}
              </ng-container>
              <span *ngIf="col.percentage && !col.frozenLeft">{{value | number: '1.0-0'}} %</span>
              <span *ngIf="!col.percentage && !col.frozenLeft">{{value}}</span>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
      <div class="flex flex-col md:flex-row w-full" *ngIf="!offenseEventsViewShown">
        <ngx-datatable
            style="width: 100%; box-shadow: none"
            class="material"
            [rows]="defenseEventsValRows"
            [columns]="offenseEventsValCols"
            [columnMode]="'force'"
            [limit]="100"
            rowHeight="auto"
            [scrollbarH]="true"
            [footerHeight]="false"
            [headerHeight]="50"
        >
          <ngx-datatable-column *ngFor="let col of offenseEventsValCols"
                                [prop]="col.prop"
                                [width]="col.width"
                                [sortable]="false"
                                [resizeable]="col.resizeable"
                                [headerClass]="col.frozenLeft ? 'column-left': ''">
            <ng-template ngx-datatable-header-template>
              <ng-container  *ngIf="col.frozenLeft">{{col.name}}</ng-container>
              <span *ngIf="!col?.frozenLeft">{{col.name}}</span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <ng-container  *ngIf="col.frozenLeft">
                {{value}}
              </ng-container>
              <span *ngIf="col.percentage && !col.frozenLeft">
                {{value | number: '1.0-0'}} %
              </span>
              <span [style.font-weight]="col.frozenLeft ? 'bold' : ''"
                    *ngIf="!col.percentage && !col.frozenLeft">
                {{value}}
              </span>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>
  </app-dashboard-accordion>

  <app-dashboard-accordion
      [title]="'TEAM EVENTS'"
      [description]="'(All the consolidated figures from Offense and Defense events)'"
      [isOpen]="teamEventsAccordionOpen"
      (toggle)="onAccordionTeamEventsToggle($event)"
  >
    <!--BOTTOM-->
    <div *ngIf="teamEventsAccordionOpen" class="grid-col w-full">
      <div style="margin-top: 15px;" class="flex flex-col md:flex-row w-full">
        <div class="flex flex-col w-full md:w-1/2 pl-2">
          <ngx-datatable
            style="width: 100%; box-shadow: none"
            class="material"
            [rows]="teamAggregatedTbl3$ | async"
            [columns]="totalAvg"
            [columnMode]="'flex'"
            [limit]="100"
            rowHeight="auto"
            [scrollbarH]="!core.isMobileDevice"
            [footerHeight]="false"
            [headerHeight]="50"
          >
          <ngx-datatable-column *ngFor="let col of totalAvg"
                                         [prop]="col.prop"
                                         [flexGrow]="col.flexGrow"
                                         [sortable]="col.sortable"
                                         [resizeable]="col.resizeable"
                                         [headerClass]="col.frozenLeft ? 'column-left': ''">
                            <ng-template ngx-datatable-header-template>
                               <ng-container  *ngIf="col.frozenLeft">{{col.name}}</ng-container>
                                <span *ngIf="!col.frozenLeft">{{col.name}}</span>
                            </ng-template>
                            <ng-template let-value="value" ngx-datatable-cell-template>
                                <ng-container  *ngIf="col.frozenLeft">{{value}}</ng-container>
                                <span *ngIf="!col.frozenLeft">{{value}}</span>
                            </ng-template>
                    </ngx-datatable-column>
          </ngx-datatable>
        </div>
        <div class="flex flex-col w-full mt-2 md:mt-0 md:w-1/2 pl-2">
          <ngx-datatable
            style="width: 100%; box-shadow: none"
            class="material"
            [rows]="teamAggregatedTbl4$ | async"
            [columns]="totalAvg"
            [columnMode]="'flex'"
            [limit]="100"
            rowHeight="auto"
            [scrollbarH]="!core.isMobileDevice"
            [footerHeight]="false"
            [headerHeight]="50"
          >
          <ngx-datatable-column *ngFor="let col of totalAvg"
                                         [prop]="col.prop"
                                         [flexGrow]="col.flexGrow"
                                         [sortable]="col.sortable"
                                         [resizeable]="col.resizeable"
                                         [headerClass]="col.frozenLeft ? 'column-left': ''">
                            <ng-template ngx-datatable-header-template>
                               <ng-container  *ngIf="col.frozenLeft">{{col.name}}</ng-container>
                                <span *ngIf="!col.frozenLeft">{{col.name}}</span>
                            </ng-template>
                            <ng-template let-value="value" ngx-datatable-cell-template>
                                <ng-container  *ngIf="col.frozenLeft">{{value}}</ng-container>
                                <span *ngIf="!col.frozenLeft">{{value}}</span>
                            </ng-template>
                    </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </app-dashboard-accordion>
  <app-dashboard-accordion
      [title]="'PACE & PHASES DEVELOPMENT'"
      [description]="'(Benchmark of the team selected during the last games)'"
      [isOpen]="paceAndPhasesAccordionOpen"
      (toggle)="onAccordionPaceAndPhasesToggle($event)"
  >
    <div *ngIf="paceAndPhasesAccordionOpen" class="grid-col w-full mt-4">
      <div *ngIf="core.isMobileDevice" class="expand-toggle">
        <ion-toggle (ionChange)="onExpandPacePhasesTableColumnsClick()"></ion-toggle>
      </div>
      <div class="grid w-full mt-2">
        <ngx-datatable
          style="width: 100%; box-shadow: none"
          class="material w-full ml-1"
          [rows]="last6GamesRows$|async"
          [columns]="last6GamesColumns$Last"
          [limit]="100"
          rowHeight="auto"
          [scrollbarH]="true"
          [footerHeight]="50"
          [headerHeight]="50"
          [columnMode]="'force'"
        >
        <ngx-datatable-column *ngFor="let col of last6GamesColumns$Last; let i=index;"
                                         [prop]="col.prop"
                                         [flexGrow]="1"
                                         [width]="!core.isMobileDevice || pacePhasesTableColsShown ? 100 : 75"
                                         [sortable]="col.frozenLeft ? false : true"
                                         [resizeable]="col.resizeable"
                                         [headerClass]="'column-left'">
                            <ng-template ngx-datatable-header-template>
                               {{col.name}}
                            </ng-template>
                            <ng-template let-value="value" ngx-datatable-cell-template>
                              <span [class.text-start]="i==0" [innerHtml]="value"></span>
                            </ng-template>
                    </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>
  </app-dashboard-accordion>
  <app-dashboard-accordion
      [title]="'PLAYERS'"
      [description]="'(Table with the performance of your players)'"
      [isOpen]="playersAccordionOpen"
      (toggle)="onAccordionPlayersToggle($event)"
  >
    <div *ngIf="playersAccordionOpen" class="grid-col w-full mt-4">
      <div class="display-flex-space-between">
        <div class="checkbox-container">
          <ion-checkbox labelPlacement="end" color="primary" (ionChange)="onPositionCheckboxClick()" [(ngModel)]="leftWingCheckbox">
            <span class="position-txt">
              {{ 'Left Wing (LW)' | translate }}
            </span>
          </ion-checkbox>

          <ion-checkbox labelPlacement="end" color="primary" (ionChange)="onPositionCheckboxClick()" [(ngModel)]="rightWingCheckbox">
            <span class="position-txt">
                {{ 'Right Wing (RW)' | translate }}
            </span>
          </ion-checkbox>

          <ion-checkbox labelPlacement="end" color="primary" (ionChange)="onPositionCheckboxClick()" [(ngModel)]="leftBackCheckbox">
            <span class="position-txt">
                {{ 'Left Back (LB)' | translate }}
            </span>
          </ion-checkbox>

          <ion-checkbox labelPlacement="end" color="primary" (ionChange)="onPositionCheckboxClick()" [(ngModel)]="rightBackCheckbox">
            <span class="position-txt">
                {{ 'Right Back (RB)' | translate }}
            </span>
          </ion-checkbox>

          <ion-checkbox labelPlacement="end" color="primary" (ionChange)="onPositionCheckboxClick()" [(ngModel)]="centerBackCheckbox">
            <span class="position-txt">
                {{ 'Center Back (CB)' | translate }}
            </span>
          </ion-checkbox>

          <ion-checkbox labelPlacement="end" color="primary" (ionChange)="onPositionCheckboxClick()" [(ngModel)]="linePlayerCheckbox">
            <span class="position-txt">
                {{ 'Line Player (LP)' | translate }}
            </span>
          </ion-checkbox>

          <ion-checkbox labelPlacement="end" color="primary" (ionChange)="onPositionCheckboxClick()" [(ngModel)]="goalkeeperCheckbox">
             <span class="position-txt">
              {{ 'Goalkeeper (GK)' | translate }}
            </span>
          </ion-checkbox>
        </div>
        <div *ngIf="!core.isMobileDevice" style="display: flex; justify-content: end">
          <button
              (click)="personalizeColumnsClick()"
              class="personalize-cols-button
              focus:outline-none focus:ring focus:border-blue-400
              active:bg-blue-800
              rounded-xl
              px-8 py-3
              capitalize
            "
          >
            {{ "Personalize Columns" | translate }}
          </button>
          <button
              *ngIf="
              (core.accountEquipoService.selectedAccountEquipo$ | async).grants.includes('view_game')
              && (core.accountEquipoService.selectedAccountEquipo$ | async).grants.includes('dashboard')
              && (core.accountEquipoService.selectedAccountEquipo$ | async).grants.includes('create_game')
              && (core.accountEquipoService.selectedAccountEquipo$ | async).grants.includes('manage_teams')"
              (click)="onDownloadPlayersCSVClick()"
              class="personalize-cols-button rounded-xl px-8 py-3"
          >
            {{ "Download CSV" | translate }}
          </button>
        </div>
      </div>
      <h2 *ngIf="core.isMobileDevice" class="select-label">
        {{ "PLAYERS (Total All Games)" | translate }}
      </h2>
      <hr class="separator">
      <div *ngIf="core.isMobileDevice" class="expand-toggle">
        <ion-toggle (ionChange)="onExpandPlayersTableColumnsClick()"></ion-toggle>
      </div>
      <div class="grid w-full mt-2 span-table">
        <ngx-datatable
          style="width: 100%; box-shadow: none;"
          class="material w-full ml-1"
          [rows]="teamPlayers"
          [columns]="selectedPlayersColumns"
          [limit]="100"
          rowHeight="auto"
          [scrollbarH]="true"
          [footerHeight]="50"
          [headerHeight]="50"
          [columnMode]="'force'"
        >
        <ngx-datatable-column *ngFor="let col of selectedPlayersColumns"
                                         [prop]="col.prop"
                                         [width]="core.isMobileDevice ? col.width - 5 : col.width"
                                         [sortable]="true"
                                         [resizeable]="col.resizeable"
                                         [headerClass]="col.frozenLeft ? 'column-left' : ''">
                            <ng-template ngx-datatable-header-template>
                                <ng-container  *ngIf="col.frozenLeft">{{col.name}}</ng-container>
                                <span *ngIf="!col?.frozenLeft">{{col.name}}</span>
                            </ng-template>
                            <ng-template let-value="value" ngx-datatable-cell-template>
                                <ng-container  *ngIf="col.frozenLeft">{{value}}</ng-container>
                                <span *ngIf="!col?.frozenLeft">{{value}}</span>
                            </ng-template>
                    </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>
    <hr/>
  </app-dashboard-accordion>
  <app-dashboard-accordion
      [title]="'GAME SYSTEM'"
      [description]="'(Which action/s are most successful during the games)'"
      [isOpen]="gameSystemAccordionOpen"
      (toggle)="onAccordionGameSystemToggle($event)"
      *ngIf="isGameSystemEnabled"
  >
    <div class="grid-col w-full" *ngIf="isGameSystemEnabled && gameSystemAccordionOpen">
      <div *ngIf="core.isMobileDevice" class="expand-toggle">
        <ion-toggle (ionChange)="onExpandGameSystemTableColumnsClick()"></ion-toggle>
      </div>
      <div class="grid w-full mt-2">
        <ngx-datatable
            class="material"
            style="width: 100%; box-shadow: none"
            [rows]="gameSystemStatsView | async"
            [columns]="gameSystemColumns"
            rowHeight="auto"
            [scrollbarH]="true"
            [footerHeight]="50"
            [headerHeight]="50"
            [columnMode]="'force'"
        >
          <ngx-datatable-column prop="actionDisplayName"
                                [frozenLeft]="true"
                                [sortable]="true"
                                [resizeable]="false"
                                [cellClass]="[getRowClass, 'bold-column']"
                                [headerClass]="'column-left'"
                                width="160">
            <ng-template let-column="column" ngx-datatable-header-template>
              {{'System' | translate}}
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template >
              {{row.categoryName}} - {{row.actionDisplayName}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column prop="numberOfActionsPlayed"
                                width="70"
                                [sortable]="true"
                                [resizeable]="false">
            <ng-template let-column="column" ngx-datatable-header-template>
              <span>{{'TIMES PLAYED' | translate}}</span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template >
              <span>
                {{value}}
              </span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column prop="numberOfGoals"
                                width="70"
                                [sortable]="true"
                                [resizeable]="false">
            <ng-template let-column="column" ngx-datatable-header-template>
              <span>{{'N° GOALS' | translate}}</span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template >
              <span>
                {{value}}
              </span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column *ngIf="!core.isMobileDevice || gameSystemTableColsShown" prop="numberOfSaves"
                                width="70"
                                [sortable]="true"
                                [resizeable]="false">
            <ng-template let-column="column" ngx-datatable-header-template>
              <span>{{'N° SAVES' | translate}}</span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template >
              <span>
                {{value}}
              </span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column *ngIf="!core.isMobileDevice || gameSystemTableColsShown" prop="numberOfPostOuts"
                                width="70"
                                [sortable]="true"
                                [resizeable]="false">
            <ng-template let-column="column" ngx-datatable-header-template>
              <span>{{'N° POST / OUT' | translate}}</span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template >
              <span>
                {{value}}
              </span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column *ngIf="!core.isMobileDevice || gameSystemTableColsShown" prop="numberOfActionsPlayedWith7Meters"
                                width="70"
                                [sortable]="true"
                                [resizeable]="false">
            <ng-template let-column="column" ngx-datatable-header-template>
              <span>{{'N° 7 METERS' | translate}}</span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template >
              <span>
                {{value}}
              </span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column *ngIf="!core.isMobileDevice || gameSystemTableColsShown" prop="numberOfFault"
                                width="70"
                                [sortable]="true"
                                [resizeable]="false">
            <ng-template let-column="column" ngx-datatable-header-template>
              <span>{{'N° FAULT'|translate}}</span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template >
              <span>
                {{value}}
              </span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column *ngIf="!core.isMobileDevice || gameSystemTableColsShown" prop="numberOfSuspension"
                                width="70"
                                [sortable]="true"
                                [resizeable]="false">
            <ng-template let-column="column" ngx-datatable-header-template>
              <span>{{'N° SUSPENSION'|translate}}</span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template >
              <span>
                {{value}}
              </span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column *ngIf="!core.isMobileDevice || gameSystemTableColsShown" prop="numberOfLostBall"
                                width="70"
                                [sortable]="true"
                                [resizeable]="false">
            <ng-template let-column="column" ngx-datatable-header-template>
              <span>{{'N° LOST BALL'|translate}}</span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template >
              <span>
                {{value}}
              </span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column *ngIf="!core.isMobileDevice || gameSystemTableColsShown" prop="percentageGoals"
                                width="70"
                                [sortable]="true"
                                [resizeable]="false">
            <ng-template let-column="column" ngx-datatable-header-template>
              <span>{{'GOALS %' | translate}}</span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template >
              <span>
                {{value | number: '1.0-0'}} %
              </span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column *ngIf="!core.isMobileDevice || gameSystemTableColsShown" prop="percentageNoGoals"
                                width="70"
                                [sortable]="true"
                                [resizeable]="false">
            <ng-template let-column="column" ngx-datatable-header-template>
              <span>{{'NO GOALS %' | translate}}</span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template >
              <span>
                {{value | number: '1.0-0'}} %
              </span>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>
  </app-dashboard-accordion>
  <ionic-selectable id="columns" #selectableColumns item-content [searchPlaceholder]="'Search'|translate" [items]="playerColumns" [isMultiple]="true"
                    itemValueField="prop" itemTextField="name" [(ngModel)]="selectedPlayersColumns" (onChange)="onSelectColumns()" [canSearch]="true" [placeholder]="'Select Columns'|translate"
  >

    <ng-template ionicSelectableHeaderTemplate></ng-template>
    <ng-template ionicSelectableFooterTemplate>
      <ion-toolbar>
        <ion-item>
          <ion-button (click)="selectableColumns.confirm(); selectableColumns.close()" [color]="'secondary'" style="width: 100%;">{{'OK'}}</ion-button>
        </ion-item>
      </ion-toolbar>
    </ng-template>
    <ng-template ionicSelectableItemTemplate let-port="item" let-isPortSelected="isItemSelected">
      {{port.name ? port.name : ''}}
    </ng-template>
    <ng-template ionicSelectableValueTemplate let-ports="value">
      <div class="ionic-selectable-value-item">
        {{ports.length}}
      </div>
    </ng-template>
  </ionic-selectable>
</div>
