import { DefenseSystem, GamePlayerModel, HalftimeTypes, PlayByPlayModel, PlayerEventModel } from '@handballai/stats-calculation';
import { TimeContainer, VideoPlayByPlay, VideoPlayTimeEvent } from 'src/app/shared-services/statistics/video/model/video-tracker.util';
import { DateTime } from 'luxon';
import { PhasesActionTypes } from 'src/app/shared-services/actions/action-types';

export const createVideoPlayTimeEventFromGamePlayerModel = (
    pl: GamePlayerModel,
    startTime: DateTime,
    halfTime: HalftimeTypes,
    playerEventModel: PlayerEventModel,
    secondsSinceStartOfGame: number
): VideoPlayTimeEvent => ({
    eventTime: {
        halftime: halfTime,
        secondsSinceStartOfGame: secondsSinceStartOfGame,
        timestamp: startTime,
        secondsSinceHalftime: 0,
        minutesSinceHalftime: 0
    },
    eventType: playerEventModel,
    playerId: pl.id,
    timestamp: 0,
    tempOrderId: -1,
    seq: -1,
    indexedDbId: -1,
});

export const createPbpRecord = (
    curr: VideoPlayByPlay,
    timerContainer: TimeContainer,
    halfOffsets: {[key in HalftimeTypes]: {offset: number, lastEvent: VideoPlayByPlay}},
    videoTime: number,
): PlayByPlayModel => ({
    event: curr.event as PlayerEventModel,
    teamName: curr.teamName,
    teamMarker: curr.teamMarker,
    teamId: curr.teamId,
    phase: curr.phase as PhasesActionTypes,
    orderId: curr.orderId,
    defenseSystem: curr.defenseSystem as DefenseSystem,
    eventTime: {
        halftime: timerContainer.halftime,
        secondsSinceStartOfGame: timerContainer.secondsSinceStartOfGame + halfOffsets[timerContainer.halftime].offset,
        timestamp: timerContainer.timestamp,
        secondsSinceHalftime: timerContainer.secondsSinceHalftime,
        minutesSinceHalftime: timerContainer.minutesSinceHalftime,
        eventDuration: curr.eventTime.eventDuration,
    },
    possessions: curr.possessions,
    possessionId: curr.possessionId,
    executionPosition: curr?.executionPosition,
    offenseSystem: curr.offenseSystem,
    shotLocation: curr?.shotLocation,
    offensePlayer: curr.offensePlayer ? curr.offensePlayer : undefined,
    defensePlayer: curr.defensePlayer ? curr.defensePlayer : undefined,
    assistantPlayer: curr.assistantPlayer ? curr.assistantPlayer : undefined,
    offense: curr.offense,
    defense: curr.defense,
    important: curr.important,
    gameSystem: curr?.gameSystem,
    videoTimestamp: videoTime,
} as PlayByPlayModel);
