import { Component, Input, OnInit } from '@angular/core';
import { CoreService } from '../../../../../shared-services/core.service';
import { PopoverController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MyTeamFileDto, MyTeamFilesDto } from '../../../../../api/hai-players-api';

@Component({
  selector: 'app-upload-files-rename-modal',
  templateUrl: './upload-files-rename-modal.component.html',
  styleUrls: ['./upload-files-rename-modal.component.scss'],
})
export class UploadFilesRenameModalComponent implements OnInit {

  public formGroup: FormGroup;
  @Input() files: MyTeamFileDto[] = [];
  filesDto: MyTeamFilesDto = { join: false, joinedVideoName: '', files: this.files };

  constructor(private readonly fb: FormBuilder,
              private readonly core: CoreService,
              private readonly popoverController: PopoverController
  ) { }

  ngOnInit() {
    this.initForm();
  }

  public async onBackArrowClick() {
    await this.popoverController.dismiss();
  }

  public async onCancel(): Promise<void> {
    await this.popoverController.dismiss();
  }

  public async onConfirm(): Promise<void> {
    this.filesDto.files = this.files.map(file => ({ ...file, id: undefined }));
    await this.popoverController.dismiss(this.filesDto);
  }

  private initForm(): void {
    this.formGroup = this.fb.group({});
    this.files.forEach((file, index) => {
      this.formGroup.addControl('fileName' + index, this.fb.control(file.name, Validators.required));
    });
  }

  updateName(index: number, newName: string) {
    this.files[index].name = newName;
  }

}
