<div class="confirm-modal-wrapper my-8" >
  <div class="py-4 w-full">
    <div class="display-flex-space-between">
      <ion-button style="margin-left: 30px" (click)="onBackArrowClick()" color="secondary">
        <ion-icon style="padding: 0 4px" name="arrow-back-outline"></ion-icon>
      </ion-button>
      <span style="width: 60%; text-align: left; padding-top: 12px;">{{ 'Upload files' | translate}}</span>
    </div>
    <hr class="mx-auto my-2">
  </div>
  <!-- checkbox -->
  <div class="input-group text-black mt-2">
    <ion-checkbox class="checkbox" [(ngModel)]="filesDto.join" labelPlacement="end">
      <ion-label>{{'Combine all cuts in a single video' | translate}}</ion-label>
    </ion-checkbox>
  </div>
  <div class="input-group text-black mt-2" *ngIf="filesDto.join">
    <input type="text"
            class="focus:outline-none focus:ring focus:border-blue-400 rounded-3xl mb-5 p-2 w-full"
            [(ngModel)]="filesDto.joinedVideoName" [placeholder]="'Unified video file name' | translate">
  </div>
  <div class="input-group text-black mt-2" [formGroup]="formGroup" *ngIf="!filesDto.join">
    <div *ngFor="let file of files; let i = index">
      <input type="text"
             class="focus:outline-none focus:ring focus:border-blue-400 rounded-3xl mb-5 p-2 w-full"
             [formControlName]="'fileName' + i" [placeholder]="'File Name' | translate"
             (input)="updateName(i, $event.target.value)">
    </div>
  </div>
  <div class="button-wrapper mt-6">
    <span (click)="onCancel()" class="close-btn">{{'Close'|translate}}</span>
    <ion-button [disabled]="formGroup.invalid" color="primary" (click)="onConfirm()">{{'Confirm'|translate}}</ion-button>
  </div>
</div>
