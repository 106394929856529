import { Observable } from 'rxjs';
import { TeamMarker } from 'src/app/shared-services/game/team-marker';
import { map } from 'rxjs/operators';
import { EasyStatsService } from 'src/app/shared-services/statistics/playbyplay/consumer/easy-stats/easy-stats.service';
import { CounterModel } from '@handballai/stats-calculation';
import { GoalConsumerService } from 'src/app/shared-services/statistics/playbyplay/consumer/goal/goal-consumer.service';

const mapGoalSavePostOutRedActionFactoryFn = (): (counter: CounterModel[]) => number[] =>
    counter => counter
        .filter(
            co => co.counterType === 'GOAL' ||
                co.counterType === 'SAVE_POST_OUT' ||
                co.counterType === 'RED_ACTIONS'
        ).map(co => co.counter);

const mapGoalKeeperGoalSavePostOutFactoryFn = (): (counter: CounterModel[]) => number[] =>
    counter => counter
        .filter(
            co => co.counterType === 'GK_GOAL' ||
                co.counterType === 'GK_SAVE_POST_OUT'
        ).map(co => co.counter);

const mapOffenseGoalPerPhaseFactoryFn = (): (counter: CounterModel[]) => number[] =>
    counter => counter
        .filter(
            co => co.counterType === 'OFFENSE_GOAL-OFFENSE_POSITIONAL' ||
                co.counterType === 'OFFENSE_GOAL-COUNTER_GOAL' ||
                co.counterType === 'OFFENSE_GOAL-FAST_BREAK' ||
                co.counterType === 'OFFENSE_GOAL-PHASE_UNDEFINED'
        ).map(co => co.counter);

const mapDefenseGoalPerPhaseFactoryFn = (): (counter: CounterModel[]) => number[] =>
    counter => counter
        .filter(
            co => co.counterType === 'DEFENSE_GOAL-OFFENSE_POSITIONAL' ||
                co.counterType === 'DEFENSE_GOAL-COUNTER_GOAL' ||
                co.counterType === 'DEFENSE_GOAL-FAST_BREAK' ||
                co.counterType === 'DEFENSE_GOAL-PHASE_UNDEFINED'
        ).map(co => co.counter);

export class TeamStatsStateHelper {
    private _homeTeamStatsStateMap$ = new Map<string, Observable<any>>();
    private _visitorTeamStatsStateMap$ = new  Map<string, Observable<any>>();

    constructor(
        private readonly easyStatsService: EasyStatsService,
        private readonly goalConsumerService: GoalConsumerService,
    ) {
        // New statistics model
        this._homeTeamStatsStateMap$
            .set('goalSavePostOutRedAction', easyStatsService.homeTeamCounters$.pipe(
                map(mapGoalSavePostOutRedActionFactoryFn())
            ));
        this._visitorTeamStatsStateMap$
            .set('goalSavePostOutRedAction', easyStatsService.visitorTeamCounters$.pipe(
                map(mapGoalSavePostOutRedActionFactoryFn())));
        this._homeTeamStatsStateMap$
            .set('goalKeeperGoalSavePostOut', easyStatsService.homeTeamCounters$.pipe(
                map(mapGoalKeeperGoalSavePostOutFactoryFn())
            ));
        this._visitorTeamStatsStateMap$
            .set('goalKeeperGoalSavePostOut', easyStatsService.visitorTeamCounters$.pipe(
                map(mapGoalKeeperGoalSavePostOutFactoryFn())
            ));
        this._homeTeamStatsStateMap$
            .set('goalOffensePerPhase', easyStatsService.homeTeamCounters$.pipe(
                map(mapOffenseGoalPerPhaseFactoryFn())
            ));
        this._visitorTeamStatsStateMap$
            .set('goalOffensePerPhase', easyStatsService.visitorTeamCounters$.pipe(
                map(mapOffenseGoalPerPhaseFactoryFn())
            ));
        this._homeTeamStatsStateMap$
            .set('goalDefensePerPhase', easyStatsService.homeTeamCounters$.pipe(
                map(mapDefenseGoalPerPhaseFactoryFn())
            ));
        this._visitorTeamStatsStateMap$
            .set('goalDefensePerPhase', easyStatsService.visitorTeamCounters$.pipe(
                map(mapDefenseGoalPerPhaseFactoryFn())
            ));
        this._homeTeamStatsStateMap$
            .set('goalExecutionPosition', goalConsumerService.homeTeamCounters$);
        this._visitorTeamStatsStateMap$
            .set('goalExecutionPosition', goalConsumerService.visitorTeamCounters$);

        // Old statistics model
        this._visitorTeamStatsStateMap$
            .set('goalSavePostOutRedAction', easyStatsService.visitorTeamCounters$.pipe(
                map(mapGoalSavePostOutRedActionFactoryFn())));
    }

    public getObservableByName(teamMarker: TeamMarker, name: string): Observable<any> {
        if (teamMarker === 'HOME') {
            return this._homeTeamStatsStateMap$.get(name);
        } else {
            return this._visitorTeamStatsStateMap$.get(name);
        }
    }

}
