import { Injectable } from '@angular/core';
import { CoreService } from 'src/app/shared-services/core.service';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import {
  PbpEventBufferModel,
  PlayTimeBufferModel,
  UiUndoBaseEvent, UiUndoCommandExecutor
} from 'src/app/shared-services/ui-event-dispatcher/buffer/event-buffer.model';
import { TeamMarker } from 'src/app/shared-services/game/team-marker';
import { GamePlayerModel } from 'src/app/shared-services/model/game.model';
import { GoalZoneTypes, PostOutZoneTypes, ExecutionPositionTypes } from 'src/app/shared-services/actions/action-types';
import { EventTime, PlayerEvent, PlayerEventModel } from '@handballai/stats-calculation';
import { NGXLogger } from 'ngx-logger';
import { VideoPlayByPlay } from 'src/app/shared-services/statistics/video/model/video-tracker.util';
import {
  getDefenseSystemByTeamMarker,
  getOpponentTeamMarker,
  getTeamByTeamMarker,
  getTeamIdByTeamMarker,
  getTeamNameByTeamMarker,
  mapGamePlayerToTeamStatsPlayer
} from 'src/app/shared-services/helper/statistics-helper';

@Injectable({
  providedIn: 'root'
})
export class EventBufferService {

  private _core: CoreService;
  private timerSource$: Observable<number>;
  private timerSubscription: Subscription;
  private playByPlayBuffer: PbpEventBufferModel[] = [];
  private playTimeRecordBuffer: PlayTimeBufferModel[] = [];
  private undoEventBuffer: UiUndoBaseEvent<any>[] = [];
  private _isUndoEnabled$ = new BehaviorSubject<boolean>(false);
  private undoCommandExecutor: UiUndoCommandExecutor;
  private _liveMode: 'LIVE_MODE' | 'POST_LIVE_VIDEO_MODE' = 'LIVE_MODE';

  constructor(
      private readonly logger: NGXLogger,
  ) {
    this.undoCommandExecutor = new UiUndoCommandExecutor();
  }

  public initCore(core: CoreService) {
    this._core = core;
  }

  public init(
      liveMode: 'LIVE_MODE' | 'POST_LIVE_VIDEO_MODE'
  ): void {
    this._liveMode = liveMode;
    this.playByPlayBuffer = [];
    this.playTimeRecordBuffer = [];
    this.undoEventBuffer = [];
    this._isUndoEnabled$.next(false);
  }

  get isUndoEnabled$(): BehaviorSubject<boolean> {
    return this._isUndoEnabled$;
  }

  public stopGame(): void {
    this.timerSubscription?.unsubscribe();
    this.timerSource$ = undefined;
  }

  public async openTransaction(): Promise<void>  {
    this.timerSubscription?.unsubscribe();
    this._isUndoEnabled$.next(false);
    this.logger.debug('EventBufferService.openTransaction');
    await this.processPendingItems();
    this.playTimeRecordBuffer = [];
    this.playByPlayBuffer = [];
    this.undoEventBuffer = [];
  }

  public undoPendingChanges(): void {
    this.timerSubscription?.unsubscribe();
    this._isUndoEnabled$.next(false);
    this.logger.debug('EventBufferService.undoPendingChanges');
    this.playTimeRecordBuffer = [];
    this.playByPlayBuffer = [];
    this.undoEventBuffer.reverse().forEach(event => {
      this.undoCommandExecutor.executeUndoCommand(event, this._core.gameService);
    });
    this.undoEventBuffer = [];
  }

  public addPlayByPlayEvent(
      teamMarker: TeamMarker,
      event: PlayerEvent,
      offenseSystem: string,
      offensePlayer?: GamePlayerModel,
      defensePlayer?: GamePlayerModel,
      assistant?: GamePlayerModel,
      executionPosition?: ExecutionPositionTypes,
      shotLocation?: GoalZoneTypes | PostOutZoneTypes,
      important: boolean = false,
      gameSystem?: string,
  ): void {
    const pbpEvent = {
      teamMarker: teamMarker,
      event: event,
      offenseSystem: offenseSystem,
      offensePlayer: offensePlayer,
      defensePlayer: defensePlayer,
      assistant: assistant,
      executionPosition: executionPosition,
      shotLocation: shotLocation,
      important: important,
      gameSystem: gameSystem,
    } as PbpEventBufferModel;

    this.logger.debug('EventBufferService.addPlayByPlayEvent', pbpEvent);
    this.playByPlayBuffer.push(pbpEvent);
  }

  public addPlayTimeEvent(
      id: number,
      event: PlayerEventModel,
      eventTime: EventTime
  ): void {
    const ptEvent = {
      id: id,
      event: event,
      eventTime: {...eventTime},
    } as PlayTimeBufferModel;
    this.logger.debug('EventBufferService.addPlayTimeEvent', ptEvent);
    this.playTimeRecordBuffer.push(ptEvent);
  }

  public addUndoEvent(event: UiUndoBaseEvent<any>): void {
    this.undoEventBuffer.push(event);
  }

  public async closeTransaction(): Promise<void> {
    this.logger.debug('EventBufferService.closeTransaction');
    if (this._liveMode === 'LIVE_MODE') {
      this.timerSubscription = timer(15000)
          .subscribe(async () => {
            this.closeTransactionNow();
          });
      this._isUndoEnabled$.next(true);
    } else {
      this._isUndoEnabled$.next(false);
      await this.processPendingItems();
      this.playTimeRecordBuffer = [];
      this.playByPlayBuffer = [];
      this.undoEventBuffer = [];
    }
  }

  public async closeTransactionNow() {
    this._isUndoEnabled$.next(false);
    await this.processPendingItems();
    this.playTimeRecordBuffer = [];
    this.playByPlayBuffer = [];
    this.undoEventBuffer = [];
    this.timerSubscription?.unsubscribe();
  }

  private async processPendingItems(): Promise<void> {
    if (this._liveMode === 'LIVE_MODE') {
      for (const ptBuffer of this.playTimeRecordBuffer) {
        await this._core.eventFeedService.addPlayTimeEvent(
            ptBuffer.id,
            ptBuffer.event,
            ptBuffer.eventTime
        );
      }

      for (const pbpBuffer of this.playByPlayBuffer) {
        // Feed pbp directly to statistics services
        await this._core.eventFeedService.addPlayByPlayEvent(
            pbpBuffer.teamMarker,
            pbpBuffer.event,
            pbpBuffer.offenseSystem,
            pbpBuffer?.offensePlayer,
            pbpBuffer?.defensePlayer,
            pbpBuffer?.assistant,
            pbpBuffer?.executionPosition,
            pbpBuffer?.shotLocation,
            pbpBuffer.important ? pbpBuffer.important : false,
            pbpBuffer?.gameSystem
        );
      }
    } else { // VIDEO MODE
      for (const ptBuffer of this.playTimeRecordBuffer) {
        this._core.videoTrackerService.addPlayTimeEvent({
          eventType: ptBuffer.event,
          eventTime: ptBuffer.eventTime,
          playerId: ptBuffer.id,
          tempOrderId: -1,
          timestamp: ptBuffer.eventTime.secondsSinceStartOfGame
        });
      }

      for (const pbpBuffer of this.playByPlayBuffer) {
        const pbp: VideoPlayByPlay = {
          event: pbpBuffer.event.eventType,
          eventTime: pbpBuffer.event.eventTime,
          orderId: -1,
          teamMarker: pbpBuffer.teamMarker,
          teamId: getTeamIdByTeamMarker(pbpBuffer.teamMarker, this._core.gameService.gameModel),
          teamName: getTeamNameByTeamMarker(pbpBuffer.teamMarker, this._core.gameService.gameModel),
          possessions: -1,
          possessionId: -1,
          phase: pbpBuffer.event.phase,
          offensePlayer: pbpBuffer.offensePlayer ? mapGamePlayerToTeamStatsPlayer(pbpBuffer.offensePlayer) : undefined,
          defensePlayer: pbpBuffer.defensePlayer ? mapGamePlayerToTeamStatsPlayer(pbpBuffer.defensePlayer) : undefined,
          assistantPlayer: pbpBuffer.assistant ? mapGamePlayerToTeamStatsPlayer(pbpBuffer.assistant) : undefined,
          offense: getTeamByTeamMarker(pbpBuffer.teamMarker, this._core.gameService.gameModel),
          defense: getTeamByTeamMarker(getOpponentTeamMarker(pbpBuffer.teamMarker), this._core.gameService.gameModel),
          defenseSystem: getDefenseSystemByTeamMarker(getOpponentTeamMarker(pbpBuffer.teamMarker), this._core.gameService.gameModel),
          executionPosition: pbpBuffer.executionPosition,
          shotLocation: pbpBuffer.shotLocation,
          offenseSystem: pbpBuffer.offenseSystem,
          important: pbpBuffer.important,
          gameSystem: pbpBuffer.gameSystem ? pbpBuffer.gameSystem : undefined,
          tempOrderId: -1
        };

        console.log('ARSA-TEST[PBP-proccess]', pbpBuffer.event.eventTime.secondsSinceStartOfGame)
        // Send pbp to video tracker service (minimal stats + feed to stats service on game end)
        this._core.videoTrackerService.addPlayByPlayEvent(pbp);
      }
    }
  }
}
