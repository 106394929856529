import { TimerModel } from 'src/app/shared-services/timer/timer-model';
import { DateTime } from 'luxon';
import { HalftimeTypes, PlayByPlayModel, PlaytimeEventModel } from '@handballai/stats-calculation';
import { NGXLogger } from 'ngx-logger';
import { EventTime } from '@handballai/stats-calculation/model/common-statistics.model';
import { GamePlayerModel } from 'src/app/shared-services/model/game.model';

export type FunctionType = 'CONST_FUNCTION' | 'LINEAR_FUNCTION' | 'HALF_TIME_BREAK' | 'LEAD_IN';
export type ClockModeType = 'START' | 'STOP';


export interface ExtendedTimerModel extends TimerModel {
  currentClockMode: ClockModeType;
}
export interface GameClockTick {
  gameSeconds: number;
  clockMode: ClockModeType;
}

export interface ClockEvent {
  clockEventType: ClockModeType;
  orderId: number | undefined;
  timestamp: number;
}

export interface VideoPlayByPlay extends PlayByPlayModel {
  tempOrderId: number;
}

export interface VideoPlayTimeEvent extends PlaytimeEventModel {
  tempOrderId: number;
  timestamp: number;
}


export class VideoPlayTimeCounter {
  constructor(
      private counter = 0
  ) {
  }

  public increment(): number {
    this.counter++;
    return this.counter;
  }
}

export class VideoPlayTimeContainer {
  constructor(
      private readonly playTimeCounter: VideoPlayTimeCounter,
      private readonly logger: NGXLogger,
      public playerId: number,
      public gamePlayerModel: GamePlayerModel,
      public videoPlayTimeEvents: VideoPlayTimeEvent[] = []
  ) {
  }

  public addPlayTimeEvent(event: VideoPlayTimeEvent, maxVideoEvent: number): VideoPlayTimeContainer {
    if (maxVideoEvent > event.eventTime.secondsSinceStartOfGame) {
      event.eventTime.secondsSinceStartOfGame = Math.round(maxVideoEvent);
    }
    this.videoPlayTimeEvents.push(event);
    this.videoPlayTimeEvents = this.videoPlayTimeEvents
        .sort(
            (a, b) =>
                a.tempOrderId - b.tempOrderId
        );
    this.logger.debug('VideoPlayTimeContainer.addPlayTimeEvent(): ', this.videoPlayTimeEvents);
    return new VideoPlayTimeContainer(
        this.playTimeCounter,
        this.logger,
        this.playerId,
        this.gamePlayerModel,
        [...this.videoPlayTimeEvents]
    );
  }

  private sanitizeEvents(event: VideoPlayTimeEvent): void {
    const foundElementIndex = this.videoPlayTimeEvents.findIndex(elem => elem.tempOrderId === event.tempOrderId);
    if (foundElementIndex === -1) {
      this.logger.fatal(
          'VideoPlayTimeContainer.sanitizeEvent() - strange the newly added event is not found: ',
          event,
          this.videoPlayTimeEvents
      );
      return;
    } else if (foundElementIndex === this.videoPlayTimeEvents.length - 1) {
      this.logger.debug('VideoPlayTimeContainer.sanitizeEvent() added to the end');
      return;
    } else if (foundElementIndex === 0 && event.eventType === 'PLAY_TIME_END') {

    }
    const playTimeEventOfNext = this.videoPlayTimeEvents[foundElementIndex + 1].eventType;
    if (event.eventType === 'PLAY_TIME_START' && playTimeEventOfNext === 'PLAY_TIME_START') {
      this.logger.info('VideoPlayTimeContainer.sanitizeEvent() play time event has been moved to front - START', event);
      this.videoPlayTimeEvents = [
        ...this.videoPlayTimeEvents.filter((_, index) => index !== foundElementIndex + 1)
      ];
      return;
    }
    if (event.eventType === 'PLAY_TIME_END' && playTimeEventOfNext === 'PLAY_TIME_END') {
      this.logger.info('VideoPlayTimeContainer.sanitizeEvent() play time event has been moved to front - END', event);
      this.videoPlayTimeEvents = [
        ...this.videoPlayTimeEvents.filter((_, index) => index !== foundElementIndex + 1)
      ];
      return;
    }
  }

}

export interface TimeContainer extends EventTime {
  videoSeconds: number;
}

export interface PossessionCounterHolder {
  possessions: number;
  possessionId: number;
}

export class ScoreHelper {
  constructor(
      public homeScore = 0,
      public visitorScore = 0
  ) {
  }

  public addHome(): ScoreHelper {
    return new ScoreHelper(
        this.homeScore + 1,
        this.visitorScore
    );
  }

  public addVisitor(): ScoreHelper {
    return new ScoreHelper(
        this.homeScore,
        this.visitorScore + 1
    );
  }
}


export const calculateScore = (vpbp: VideoPlayByPlay[]): ScoreHelper =>
    vpbp.reduce(
        (helper, curr) => {
          if (curr.teamMarker === 'HOME' && curr.event === 'GOAL') {
            return helper.addHome();
          } else if (curr.teamMarker === 'VISITOR' && curr.event === 'GOAL') {
            return helper.addVisitor();
          } else {
            return helper;
          }
        },
        new ScoreHelper()
    );

export const calculateNextTimerModelFromVideoTimerTick = (
    startTime: DateTime,
    gameTime: number,
    currentClockMode: ClockModeType,
    halfTime: HalftimeTypesExtended,
    videoClock: number
): ExtendedTimerModel => {
  const minutes = Math.floor(gameTime / 60);
  const remainingSeconds = gameTime - (minutes * 60);
  return {
    seconds: remainingSeconds,
    minutes: minutes,
    counterSecSinceStart: gameTime,
    gameCounter: `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`,
    halfTime: halfTime,
    wallClock: startTime.plus({seconds: videoClock}),
    currentClockMode: currentClockMode
  };
};


const calcY = (
  videoSignalTimestamp: number,
  functionType: FunctionType,
  leftBorderX: number,
  leftBorderY: number,
): number => {
  if (functionType === 'LINEAR_FUNCTION') {
    return videoSignalTimestamp - leftBorderX + leftBorderY;
  } else {
    return leftBorderY;
  }
};

const cleanup = (trackerList: VideoTrackerNode[]): VideoTrackerNode[] =>
  trackerList.reduce((accu, curr, indx) => {
    if (indx === 0) {
      accu.push(curr);
    } else if ((indx + 1) < trackerList.length) {
      const prev = accu[indx - 1];
      curr.leftBorderX = prev.rightBorderX;
      curr.leftBorderY = prev.rightBorderY;
      curr.rightBorderY = calcY(curr.rightBorderX, curr.functionType, curr.leftBorderX, curr.leftBorderY);
      accu.push(curr);
    } else {
      const prev = accu[indx - 1];
      curr.leftBorderX = prev.rightBorderX;
      curr.leftBorderY = prev.rightBorderY;
      curr.rightBorderX = Number.MAX_VALUE;
      curr.rightBorderY = 0;
      accu.push(curr);
    }
    return accu;
  }, [] as VideoTrackerNode[]);

export const optimize = (trackerList: VideoTrackerNode[]): VideoTrackerNode[] => {
  let deletedOffset = 0;
  return trackerList.reduce((accu, curr, indx) => {
    if (indx === 0) {
      accu.push(curr);
    } else {
      if (accu[indx - deletedOffset - 1].functionType === curr.functionType) {
        accu[indx - deletedOffset - 1].rightBorderX = curr.rightBorderX;
        accu[indx - deletedOffset - 1].rightBorderY = curr.rightBorderY;
        deletedOffset++;
      } else {
        accu.push(curr);
      }
    }
    return accu;
  }, [] as VideoTrackerNode[]);
};

export class VideoTrackerNode {
  constructor(
    public readonly id: number,
    public functionType: FunctionType,
    public leftBorderX: number,
    public leftBorderY: number,
    public rightBorderX: number = Number.MAX_VALUE,
    public rightBorderY: number = 0
  ) {
  }

  public calculateY(videoSignalTimestamp: number): number {
    return calcY(
      videoSignalTimestamp,
      this.functionType,
      this.leftBorderX,
      this.leftBorderY
    );
  }

  public isResponsible(videoSignalTimestamp: number): boolean {
    return videoSignalTimestamp >= this.leftBorderX && videoSignalTimestamp < this.rightBorderX;
  }

  public setRightBorder(rightBorderX: number, rightBorderY: number): void {
    this.rightBorderX = rightBorderX;
    this.rightBorderY = rightBorderY;
  }

}

export class VideoTrackerContainer {
  private _trackerList: VideoTrackerNode[];
  private idCounter = 0;

  constructor(
      private readonly logger: NGXLogger
  ) {
    this._trackerList = [new VideoTrackerNode(
      this.idCounter,
      'LEAD_IN',
      0,
      0
    )];
  }

  get lengthOfHalftimeGameTime(): number {
    const lastNode = this._trackerList[this._trackerList.length - 1];
    return lastNode.rightBorderY;
  }

  public calculateY(
      videoSignalTimestamp: number,
      calculateWithRightBorderInclusive = false
  ): GameClockTick {
    let trackerFound: VideoTrackerNode[];
    videoSignalTimestamp = Math.round(videoSignalTimestamp);
    if (calculateWithRightBorderInclusive) {
      trackerFound = [this._trackerList[this._trackerList.length - 1]];
    } else {
      trackerFound = this._trackerList.filter(tr => tr.isResponsible(videoSignalTimestamp));
    }
    // We take the first one
    if (!trackerFound[0]) {
      console.log(`tracker not found: ${videoSignalTimestamp}`);
    }
    return {
      gameSeconds: trackerFound[0] ? trackerFound[0].calculateY(videoSignalTimestamp) : 0,
      clockMode: trackerFound[0] && trackerFound[0].functionType === 'LINEAR_FUNCTION' ? 'START' : 'STOP'
    };
  }

  public addNode(
    clockModeType: ClockModeType,
    leftBorderX: number, // Video timestamp (real)
    leftBorderY: number // Game Time
  ): void {

    leftBorderX = Math.round(leftBorderX);
    leftBorderY = Math.round(leftBorderY);

    if (leftBorderX === 0 && clockModeType === 'STOP') {
      this._trackerList = [new VideoTrackerNode(
        this.nextId(),
        'CONST_FUNCTION',
        0,
        0
      )];
      return;
    } else if (leftBorderX === 0 && clockModeType === 'START') {
      this._trackerList = [new VideoTrackerNode(
        this.nextId(),
        'LINEAR_FUNCTION',
        0,
        0
      )];
      return;
    } else {
      this.addNodeInternal(clockModeType, leftBorderX, leftBorderY);
    }
    this.logger.debug('VideoTrackerContainer.addNode', this._trackerList);
  }

  public removeNodeWithSuspension(videoSignalTimestamp: number) {
    const trackerFound = this._trackerList.filter(tr => tr.isResponsible(videoSignalTimestamp))[0];
    if (!trackerFound) {
      return;
    }
    if (trackerFound.leftBorderX === videoSignalTimestamp && trackerFound.functionType === 'CONST_FUNCTION') {
      // exact match with just replace it and optimize
      // no suspension in the break -> is this the case
      trackerFound.functionType = 'LINEAR_FUNCTION';
      this._trackerList = cleanup(this._trackerList);
      this._trackerList = optimize(this._trackerList);
    }
    // it seems that we already had a stopped clock or it is a condition that is not covered
    return;
  }

  public endContainer(
      leftBorderX: number,
      leftBorderY: number,
      isGameEnd = false
  ): void {
    leftBorderX = Math.round(leftBorderX);
    leftBorderY = Math.round(leftBorderY);
    const lastItem = this._trackerList[this._trackerList.length - 1];
    if (isGameEnd) {
      lastItem.rightBorderX = Number.MAX_VALUE;
    } else {
      lastItem.rightBorderX = leftBorderX;
    }
    lastItem.rightBorderY = calcY(leftBorderX, lastItem.functionType, lastItem.leftBorderX, lastItem.leftBorderY);
    this.logger.debug(
        'VideoTrackerContainer.addHalftimeBreak, leftBorderX, lastItem.rightBorderX, lastItem.rightBorderY',
        leftBorderX,
        lastItem.rightBorderX,
        lastItem.rightBorderY
        );
  }

  get trackerList(): VideoTrackerNode[] {
    return this._trackerList;
  }

  private addNodeInternal(
    clockModeType: ClockModeType,
    leftBorderX: number,
    leftBorderY: number
  ): void {
    let index: number;
    const foundItem = this._trackerList.filter((tr, indx) => {
      if (tr.isResponsible(leftBorderX)) {
        index = indx;
        return tr;
      }
    })[0];
    if (foundItem.leftBorderX === leftBorderX) {
      this.replaceCurrentElement(foundItem, clockModeType);
      this._trackerList = cleanup(this._trackerList);
      this._trackerList = optimize(this._trackerList);
      return;
    }
    const elementsBefore = this._trackerList.slice(0, index);
    let elementsAfter: VideoTrackerNode[];
    if (this._trackerList.length === (index + 1)) { // we have the last element
      elementsAfter = [];
    } else {
      elementsAfter = this._trackerList.slice(index + 1, this._trackerList.length);
    }

    foundItem.setRightBorder(leftBorderX, leftBorderY);
    const newElement = new VideoTrackerNode(
      this.nextId(),
      clockModeType === 'START' ? 'LINEAR_FUNCTION' : 'CONST_FUNCTION',
      leftBorderX,
      leftBorderY
    );
    if (elementsAfter.length > 0) {
      newElement.rightBorderX = elementsAfter[0].leftBorderX;
      newElement.rightBorderY = elementsAfter[0].leftBorderY;
    }
    this._trackerList = [
      ...elementsBefore,
      foundItem,
      newElement,
      ...elementsAfter
    ];

    this._trackerList = cleanup(this._trackerList);
    this._trackerList = optimize(this._trackerList);
  }

  private replaceCurrentElement(
    foundItems: VideoTrackerNode,
    clockModeType: ClockModeType
  ): void {
    foundItems.rightBorderY = calcY(
      foundItems.rightBorderX,
      clockModeType === 'START' ? 'LINEAR_FUNCTION' : 'CONST_FUNCTION',
      foundItems.leftBorderX,
      foundItems.leftBorderY
    );
    foundItems.functionType = clockModeType === 'START' ? 'LINEAR_FUNCTION' : 'CONST_FUNCTION';
  }

  private nextId(): number {
    this.idCounter++;
    return this.idCounter;
  }
}

export declare type HalftimeTypesExtended = 'T1' | 'T2' | 'ET1' | 'ET2' | 'P';
