<div class="confirm-modal-wrapper my-8" >
  <div class="py-4 w-full">
    <span *ngIf="accountsCopy.length > 1; else folder">{{ 'Select Account' | translate}}</span>
    <ng-template #folder>
      <div [style.justify-content]="folderToBeMovedId ? 'center' : ''" class="display-flex-space-between">
        <ion-button *ngIf="!folderToBeMovedId" style="margin-left: 30px" [style.visibility]="showBackArrow ? 'visible' : 'hidden'" (click)="onBackArrowClick()" color="secondary">
          <ion-icon style="padding: 0 4px" name="arrow-back-outline"></ion-icon>
        </ion-button>
        <span class="span-with-add-btn">
          <ng-container *ngIf="!folderToBeMovedId">
            {{ 'Select Folder' | translate}}
          </ng-container>
          <ng-container *ngIf="folderToBeMovedId">
            {{ 'Select folder to move in' | translate}}
          </ng-container>
        </span>
        <ion-button *ngIf="!folderToBeMovedId" style="margin-right: 30px;" [tooltip]="'Add new Folder' | translate" (click)="addNewFolder()" color="secondary">
          <ion-icon style="padding: 0 4px" name="add-outline"></ion-icon>
        </ion-button>
      </div>
    </ng-template>
    <hr class="mx-auto my-2">
  </div>
  <div style="max-height: 250px; width: 90%; overflow-y: auto">
    <ng-container *ngIf="accountsCopy.length > 1; else foldersSection">
      <ion-item *ngFor="let account of accounts" (click)="onAccountSelect(account, true)"
                 lines="full" class="item-default clickAllowedSource">
        <div class="folder-item" style="display: flex; width: 100%; height: 100%">
          <div style="display: flex;align-items: center; width: 100%; cursor: pointer">
            <ion-label class="clickAllowedSource"> {{ account.name + ' - ' + account.account.name }} </ion-label>
          </div>
        </div>
      </ion-item>
    </ng-container>
    <ng-template #foldersSection>
      <ng-container *ngFor="let folder of folders" >
        <ion-item (click)="onFolderSelect(folder)"
                  [color]="selectedFolder?.id === folder.id ? 'alternative' : ''"
                  lines="full" class="item-default clickAllowedSource">
          <div class="folder-item" style="display: flex; width: 100%">
            <div style="display: flex; align-items: center">
              <ion-icon *ngIf="folder.children?.length"
                        class="chevron" (click)="onExpandClick(folder, $event)"
                        [name]="folder.expanded ? 'chevron-down-outline' : 'chevron-forward-outline'"></ion-icon>
              <ion-avatar class="clickAllowedSource">
                <img class="clickAllowedSource" alt="folder" src="/assets/svg/folder.svg" />
              </ion-avatar>
            </div>
            <div style="display: flex;align-items: center; width: 100%; cursor: pointer">
              <ion-label class="clickAllowedSource"> {{ folder.name }} </ion-label>
            </div>
            <ion-icon *ngIf="!folderToBeMovedId && !selectedFolderId" style="margin-left: auto; padding-top: 13px" class="chevron" [tooltip]="'Add Subfolder'" (click)="onAddSubFolderClick(folder, $event)" name="add-outline"></ion-icon>
          </div>
        </ion-item>
        <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: folder.expanded ? folder.children : [], index: 0 }"></ng-container>
      </ng-container>
      <p *ngIf="!accountHasAnyFolders && !folders.length">
        {{ 'Selected account does not have any folders available' | translate }}
      </p>
    </ng-template>

    <ng-template #recursiveList let-items let-index="index">
      <ng-container *ngFor="let item of items; let i = index">
        <ion-item (click)="onFolderSelect(item)" [color]="selectedFolder?.id === item.id ? 'alternative' : ''"
                  lines="full" class="item-default clickAllowedSource">
          <div class="folder-item" style="display: flex; align-items: center; width: 100%">
            <div [style.margin-left]="(index+1) * 25 + 'px'" style="display: flex; align-items: center">
              <ion-icon *ngIf="item.children?.length"
                        class="chevron" (click)="onExpandClick(item, $event)"
                        [name]="item.expanded ? 'chevron-down-outline' : 'chevron-forward-outline'"></ion-icon>
              <ion-avatar class="clickAllowedSource">
                <img class="clickAllowedSource" alt="folder" src="/assets/svg/folder.svg" />
              </ion-avatar>
            </div>

            <div style="display: flex; align-items: center; width: 100%; cursor: pointer">
              <ion-label class="clickAllowedSource"> {{ item.name }} </ion-label>
            </div>
            <ion-icon *ngIf="!folderToBeMovedId && !selectedFolderId" style="margin-left: auto;" class="chevron" [tooltip]="'Add Subfolder'" (click)="onAddSubFolderClick(item, $event)" name="add-outline"></ion-icon>
          </div>

        </ion-item>
        <div *ngIf="item.children && item.children.length && item.expanded">
          <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: item.children, index: index+1 }"></ng-container>
        </div>
      </ng-container>
    </ng-template>
  </div>

  <div class="button-wrapper mt-6">
    <span (click)="onCancel()" class="close-btn">{{'Close'|translate}}</span>
  </div>
</div>
