import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { ScoutingPlaylistDto, ScoutingPlaylistVideoDto } from 'src/app/api/hai-api';
import { TManageAccountEquipoGrants } from 'src/app/shared-services/account-equipo/account-equipo.service';
import { CoreService } from 'src/app/shared-services/core.service';
import { CommonUserModel } from 'src/app/shared-services/model/common-user.model';
import { ScoutingService } from '../../../../scouting.service';
import { ScoutingFolderNamePopupComponent } from '../../../scouting-folder-name-popup/scouting-folder-name-popup.component';

@Component({
  templateUrl: './play-list-selection-popover.component.html',
  styleUrls: ['./play-list-selection-popover.component.scss'],
})
export class PlayListSelectionPopoverComponent implements OnInit {
  public allPlaylists$;
  // public allPlaylists$: Observable<ScoutingPlaylistDto[]>;
  public selectedPlayList: ScoutingPlaylistDto;
  private _selectedVideos;

  @Input()
  set selectedVideos(value: ScoutingPlaylistVideoDto[]) {
    if (value) {
      this._selectedVideos = value;
    }
  }

  constructor(
    private popoverCntl: PopoverController,
    private core: CoreService,
    public scoutingService: ScoutingService
  ) {}

  ngOnInit(): void {
    this.scoutingService.getUserAndEquipo();
    this.scoutingService.getPlayListData();
  }

  async onPlaylistChange($event) {
    this.selectedPlayList = $event;
    this.onConfirmName();
  }

  public async onCancel(): Promise<void> {
    await this.popoverCntl.dismiss();
  }

  public async onConfirmName(): Promise<void> {
    this._selectedVideos.forEach((selectedVideo) => {
      if (this.selectedPlayList && !this.selectedPlayList.videos.some((v) => v.pbpId === selectedVideo.pbpId)) {
        if (selectedVideo.id) delete selectedVideo.id;
        this.selectedPlayList.videos.push(selectedVideo);
      }
    });

    this.popoverCntl.dismiss(this.selectedPlayList);
  }

  public async createPlaylist() {
    const popover = await this.core.popoverCtrl.create({
      component: ScoutingFolderNamePopupComponent,
      cssClass: 'confirm-popover',
      mode: 'ios',
      componentProps: {
        modalTitle: 'Create Playlist',
        message: 'Type the new Playlist name',
      },
    });
    popover.onDidDismiss().then(async (modalData: any) => {
      if (modalData.data) {
        const folder: ScoutingPlaylistDto = {
          id: undefined,
          name: modalData.data.name,
          deleted: false,
          videos: [],
        };
        await this.scoutingService.createPlayList(folder);
      }
    });
    await popover.present();
  }
}
