/**
 * players-api
 * players-api API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MyTeamFileDto { 
    id?: number;
    name: string;
    folderId: number;
    s3Url: string;
    type: string;
    pbpId?: number;
    videoDateTime?: string;
    eventPosition?: string;
}

