<ng-select
    class="mobile-tab-selector mt-4"
    maxSelectedItems="1"
    [clearable]="false"
    [(ngModel)]="selectedTab">
    <ng-option [value]="'OVERVIEW' | translate">
        <span class="select-options-text">
        {{ 'OVERVIEW' | translate }}
        </span>
    </ng-option>
    <ng-option [value]="'TEAM'|translate">
        <span class="select-options-text">
        {{ 'TEAM'|translate }}
        </span>
    </ng-option>
    <ng-option *ngIf="!core.isMobileDevice" [value]="'TIMELINE'|translate">
        <span class="select-options-text">
        {{ 'TIMELINE'|translate }}
        </span>
    </ng-option>
    <ng-option [value]="'SHOTS'|translate">
        <span class="select-options-text">
        {{ 'SHOTS'|translate }}
        </span>
    </ng-option>
    <ng-option [value]="'INDIVIDUAL'|translate">
        <span class="select-options-text">
        {{ 'INDIVIDUAL'|translate }}
        </span>
    </ng-option>
    <ng-option [value]="'Game System'|translate">
        <span class="select-options-text">
        {{ 'Game System'|translate }}
        </span>
    </ng-option>
    <ng-option [value]="'Play By Play'|translate">
        <span class="select-options-text">
        {{ 'Play By Play'|translate }}
        </span>
    </ng-option>
</ng-select>
<app-custom-tabs class="page-tabs flex-auto" (selection)="selectedTab = $event">
    <app-custom-tab *ngIf="!isPublic && game"  [label]="'OVERVIEW'|translate" [fullHeight]="true" [selected]="selectedTab" [defaultTab]="true">
        <app-overview-stats class="full-height advanced-stats-tab" *ngIf="('OVERVIEW'|translate)==(selectedTab|translate)"
                            [overviewStatsAggregatedViewModel]="overviewStatsStateHelper
                    .getObservableByName('overviewAggregatedModel') | async"
                            [homeOverviewStatsByPhaseModelList]="overviewStatsStateHelper
                    .getObservableByName('homeOverStatsByPhaseModelListPerDefenseSystem') | async"
                            [visitorOverviewStatsByPhaseModelList]="overviewStatsStateHelper
                    .getObservableByName('visitorOverStatsByPhaseModelListPerDefenseSystem') | async"
                            [homeOverStatsOffenseSystemModelList]="overviewStatsStateHelper
                    .getObservableByName('homeOverStatsOffenseSystemModelListPerDefenseSystem') | async"
                            [visitorOverStatsOffenseSystemModelList]="overviewStatsStateHelper
                    .getObservableByName('visitorOverStatsOffenseSystemModelListPerDefenseSystem') | async">
        </app-overview-stats>
    </app-custom-tab>

    <app-custom-tab *ngIf="!isPublic && game"  [label]="'TEAM'|translate" [fullHeight]="true" [selected]="selectedTab">
        <app-team-stats [isPublic]="isPublic" class="full-height advanced-stats-tab" *ngIf="('TEAM'|translate)==(selectedTab|translate)"
                        [homeTeamOverStats]="teamOverviewStatsStateHelper
                    .getObservableByName('homeTeamOverview') | async"
                        [visitorTeamOverStats]="teamOverviewStatsStateHelper
                    .getObservableByName('visitorTeamOverview') | async"
                        [homeTeamEventCounters]="teamOverviewStatsStateHelper
                    .getObservableByName('homeTeamEventCounters') | async"
                        [visitorTeamEventCounters]="teamOverviewStatsStateHelper
                    .getObservableByName('visitorTeamEventCounters') | async"
                        [homePlayerEventsTbl$]="teamOverviewStatsStateHelper
                    .getObservableByName('homePlayerEventsTbl$')"
                        [visitorPlayerEventsTbl$]="teamOverviewStatsStateHelper
                    .getObservableByName('visitorPlayerEventsTbl$')">
        </app-team-stats>
    </app-custom-tab>

    <app-custom-tab *ngIf="!isPublic && game" [label]="'TIMELINE'|translate" [fullHeight]="true" [selected]="selectedTab">
        <app-timeline-stats class="full-height advanced-stats-tab" *ngIf="('TIMELINE'|translate)==(selectedTab|translate)"
                            [timeLineStatsGoalsFirstHalf]="timelineStatsStateHelper
                        .getObservableByName('goalsFirstHalf') | async"
                            [timeLineStatsGoalsSecondHalf]="timelineStatsStateHelper
                        .getObservableByName('goalsSecondHalf') | async"
                            [timeLineStatsGoalsPossessionOverview]="timelineStatsStateHelper
                        .getObservableByName('goalPossessionOverview') | async"
                            [timeLineStatsXGoalTimeSeries]="timelineStatsStateHelper
                        .getObservableByName('xGoalGraph') | async"
                            [accumulatedCounterListWithTiming]="timelineStatsStateHelper
                        .getObservableByName('accumulatedCounterList') | async"
                            [semaphoreRows]="timelineStatsStateHelper
                        .getObservableByName('semaphor') | async">
        </app-timeline-stats>
    </app-custom-tab>

    <app-custom-tab [label]="'SHOTS'|translate" [fullHeight]="true" [selected]="selectedTab" [defaultTab]="!(!isPublic && game)">
        <app-shots-stats class="full-height advanced-stats-tab" *ngIf="('SHOTS'|translate)==(selectedTab|translate) || (!(!isPublic && game) && ('OVERVIEW'|translate)==(selectedTab|translate))"
                            [homeTeamLabel]="gameService.gameModel.home.name"
                            [visitorTeamLabel]="gameService.gameModel.visitor.name"
                            [homeGoalCounters]="shotsStatsStateHelper
                    .getObservableByName('homeGoalCounter') | async"
                            [visitorGoalCounters]="shotsStatsStateHelper
                    .getObservableByName('visitorGoalCounter') | async"
                            [homeGoalkeeperEfficiencyCounters]="shotsStatsStateHelper
                    .getObservableByName('homeGoalkeeperEfficiency') | async"
                            [visitorGoalkeeperEfficiencyCounters]="shotsStatsStateHelper
                    .getObservableByName('visitorGoalkeeperEfficiency') | async"
                            [homeGoalConnectionCounter]="shotsStatsStateHelper
                    .getObservableByName('homeGoalConnectionCounter') | async | shotTransform"
                            [visitorGoalConnectionCounter]="shotsStatsStateHelper
                    .getObservableByName('visitorGoalConnectionCounter') | async | shotTransform">
        </app-shots-stats>
    </app-custom-tab>

    <app-custom-tab [label]="'INDIVIDUAL'|translate" [fullHeight]="true" [selected]="selectedTab">
        <app-individual-stats class="full-height advanced-stats-tab" *ngIf="('INDIVIDUAL'|translate)==(selectedTab|translate)"
                                [players]="players$ | async"
                                [individualStatsKpiModeList]="teamOverviewStatsStateHelper
                    .getObservableByName('individualStatsKpiModelList') | async"
                                [individualStatsEventModelList]="teamOverviewStatsStateHelper
                    .getObservableByName('individualStatsEventsModelList') | async"
                                [individualStatsModel1]="teamOverviewStatsStateHelper
                    .getObservableByName('individualStatsModel1') | async"
                                [individualStatsGoalsModelList]="teamOverviewStatsStateHelper
                    .getObservableByName('individualStatsGoalsModelList') | async"
                                [selectedPlayerGoalCounters]="individualStatsStateHelper
                    .getObservableByName('goalCounter') | async"
                                [selectedPlayerEventList]="individualStatsStateHelper
                    .getObservableByName('playerEventList') | async"
                                [selectedPlayerPlayTimeInfo]="individualStatsStateHelper
                    .getObservableByName('playTimeEventList') | async"
                                [selectedPlayerGoalConnectionCounters]="individualStatsStateHelper
                    .getObservableByName('goalConnectionCounter') | async | shotTransform"
                                (playerSelected$)="onPlayerSelected($event)">
        </app-individual-stats>
    </app-custom-tab>

    <app-custom-tab [label]="'Game System' | translate" [fullHeight]="true" [selected]="selectedTab" *ngIf="!isPublic">
        <app-game-system-stats-container class="full-height advanced-stats-tab" *ngIf="('Game System'|translate)==(selectedTab)"
                               [homeTeamName]="gameSystemStatsHelper
                               .getObservableByName('homeGameSystemTeamName') | async"
                               [visitorTeamName]="gameSystemStatsHelper
                               .getObservableByName('visitorGameSystemTeamName') | async"
                               [homeGameSystemStatsView]="gameSystemStatsHelper
                               .getObservableByName('homeGameSystemStatsView') | async"
                               [visitorGameSystemStatsView]="gameSystemStatsHelper
                               .getObservableByName('visitorGameSystemStatsView') | async"
                                [homePlayerGameSystemStatsView]="gameSystemStatsHelper
                                .getObservableByName('homePlayerGameSystemStatsView') | async"
                                [visitorPlayerGameSystemStatsView]="gameSystemStatsHelper
                                .getObservableByName('visitorPlayerGameSystemStatsView') | async">
        </app-game-system-stats-container>
    </app-custom-tab>

    <app-custom-tab *ngIf="(game && isGameTracker) || (!isPublic && game && (showPlayByPlay$|async))" [label]="'Play By Play'|translate" [fullHeight]="true" [selected]="selectedTab">
        <app-historic-play-by-play class="advanced-stats-tab" [players]="players$ | async" [editable]="editable"
                                    *ngIf="('Play By Play'|translate)==(selectedTab|translate)"
                                    [playByPlayRecords]="playByPlayRecords$ | async"
                                    [startTimeOfGame]="game.date" [game]="game">
        </app-historic-play-by-play>
    </app-custom-tab>
</app-custom-tabs>
